export const backendUrl = process.env.REACT_APP_BACKEND_URL
export const IS_LOCAL = (process.env.REACT_APP_IS_LOCAL === 'true');
export const IS_LIVE = (process.env.REACT_APP_IS_LIVE === 'true');
export const IS_DEMO = (process.env.REACT_APP_IS_DEMO === 'true');
export const kimbo_google_client_id =
  "546014742341-5tmh4tjc1iuc0sht3for6ogip2tooo1o.apps.googleusercontent.com";
export const privacy = "https://inproved.com/privacy-policy/";
export const terms = "https://inproved.com/terms-of-service/";
export const termsOfAdvisoryPlan = "https://inproved.com/terms-of-advisory-plan/";
export const guide = "https://inproved.com/guides/#digital-services"
export const country = [
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];
export const companyCountry = JSON.parse(JSON.stringify(country));
export const TZ = 'Asia/Singapore';
export const CAPITAL = {
  shared: 1,
  paidUp: 2,
};

export const HOLDERS = {
  promoters: 1,
  shareHolders: 2,
  directors: 3,
  secretary: 4,
  officer: 5,
};

export const HOLDERS_PAGE_TYPE = {
  DASHBOARD: 1,
  INVITES: 2,
  PENDING_GLOBAL_INVITES: 3
};

export const POSITIONS = [
  {
    value: 1,
    name: "Promoter",
  },
  {
    value: 2,
    name: "Shareholder",
  },
  {
    value: 3,
    name: "Director",
  },
  {
    value: 4,
    name: "Secretary",
  },
  {
    value: 5,
    name: "Officer",
  },
];

export const POTENTIAL_PURPOSE_PROFILES = [
  {
    value: 1,
    name: "Supplier",
  },
  {
    value: 2,
    name: "Customer",
  },
  {
    value: 3,
    name: "Investor",
  },
  {
    value: 4,
    name: "Partner",
  },
];

export const POTENTIAL_PURPOSE_PRODUCT_SERVICES = [
  {
    value: 1,
    name: "For sale",
  },
  {
    value: 2,
    name: "For licensing",
  },
];

export const POTENTIAL_PURPOSE_COMPANIES = [
  {
    value: 1,
    name: "For collaboration",
  },
  {
    value: 2,
    name: "For investment",
  },
];

export const MESSAGE_TYPE = {
  BANNER: 1,
  ASKADDA: 2,
  LEAVEAMESSAGE: 3,
  BOB_RESCHEDULE: 4,
  BIDASK: 5,
  USER_DASHBOARD: 6,
  FORMALISE_MY_HOLDING_COMPANY: 7,
  CONTACT_US_BUTTON: 8,
}

export const ACTIVITY_OLD = [
  {
    code: "01111",
    title: "Growing of leafy and fruit vegetables",
  },
  {
    code: "01112",
    title: "Growing of mushrooms",
  },
  {
    code: "01113",
    title: "Growing of root crops",
  },
  {
    code: "01119",
    title: "Growing of food crops (non-hydroponics) n.e.c.",
  },
  {
    code: "01120",
    title: "Growing of leafy and fruit vegetables (hydroponics)",
  },
  {
    code: "01130",
    title: "Growing of fruits",
  },
  {
    code: "01141",
    title: "Growing of orchids",
  },
  {
    code: "01142",
    title: "Growing of ornamental plants",
  },
  {
    code: "01149",
    title: "Growing of nursery products n.e.c.",
  },
  {
    code: "01190",
    title: "Growing of other crops",
  },
  {
    code: "01411",
    title: "Pig farms",
  },
  {
    code: "01412",
    title: "Cattle farms (including dairy cattle)",
  },
  {
    code: "01413",
    title: "Goat farms (including goat's milk production)",
  },
  {
    code: "01421",
    title: "Poultry breeding/hatcheries",
  },
  {
    code: "01422",
    title: "Broiler farms (chickens reared for meat)",
  },
  {
    code: "01423",
    title: "Layer farms (chickens reared for eggs)",
  },
  {
    code: "01424",
    title: "Duck farms",
  },
  {
    code: "01491",
    title: "Dog breeding",
  },
  {
    code: "01492",
    title: "Bird breeding",
  },
  {
    code: "01493",
    title: "Crocodile farms",
  },
  {
    code: "01494",
    title: "Frog farms",
  },
  {
    code: "01499",
    title: "Other animal production n.e.c.",
  },
  {
    code: "01500",
    title: "Growing of crops combined with animal production (mixed farming)",
  },
  {
    code: "01600",
    title:
      "Agricultural and animal husbandry service activities except veterinary activities",
  },
  {
    code: "02000",
    title: "Forestry, logging and related service activities",
  },
  {
    code: "03101",
    title: "Fishing in kelongs",
  },
  {
    code: "03102",
    title: "Ocean fishing except fishing in kelongs",
  },
  {
    code: "03103",
    title: "Coastal fishing except fishing in kelongs",
  },
  {
    code: "03109",
    title: "Fishing n.e.c.",
  },
  {
    code: "03201",
    title: "Food fish farms",
  },
  {
    code: "03202",
    title: "Ornamental fish farms",
  },
  {
    code: "03209",
    title:
      "Operation of fish hatcheries and fish farms n.e.c. (including turtle, prawn, crab, mussel farms)",
  },
  {
    code: "08101",
    title: "Stone quarrying",
  },
  {
    code: "08102",
    title: "Quarrying of sand and clay",
  },
  {
    code: "08900",
    title: "Other mining and quarrying",
  },
  {
    code: "09001",
    title: "Crude petroleum and natural gas production",
  },
  {
    code: "09002",
    title:
      "Service activities incidental to oil and gas extraction (excluding surveying and engineering design and consultancy services supporting mining, oil and gas extraction and offshore exploration activities)",
  },
  {
    code: "10101",
    title: "Slaughtering of livestock and poultry",
  },
  {
    code: "10102",
    title: "Manufacture of sausage",
  },
  {
    code: "10103",
    title:
      "Preparing, canning and preserving of livestock and livestock products (including lard and other edible animal fats)",
  },
  {
    code: "10104",
    title: "Preparing, canning and preserving of poultry and poultry products",
  },
  {
    code: "10109",
    title: "Processing and preserving of meat and meat products n.e.c.",
  },
  {
    code: "10200",
    title: "Processing and preserving of seafood",
  },
  {
    code: "10301",
    title: "Manufacture of jams (including fruit jelly)",
  },
  {
    code: "10302",
    title: "Other canning and preserving of fruits and fruit juices",
  },
  {
    code: "10303",
    title:
      "Other canning and preserving of vegetables and vegetable juices (including pickles)",
  },
  {
    code: "10401",
    title: "Manufacture of edible vegetable and animal oils and fats",
  },
  {
    code: "10402",
    title: "Manufacture of inedible vegetable and animal oils and fats",
  },
  {
    code: "10409",
    title: "Manufacture of vegetable and animal oils and fats n.e.c.",
  },
  {
    code: "10501",
    title:
      "Manufacture of condensed and evaporated milk (including pasteurising and bottling of fluid milk)",
  },
  {
    code: "10502",
    title: "Manufacture of milk powder",
  },
  {
    code: "10503",
    title: "Manufacture of ice-cream",
  },
  {
    code: "10509",
    title:
      "Manufacture of dairy products n.e.c. (e.g. butter, cheese, ice-cream mixes and powder, yoghurt)",
  },
  {
    code: "10611",
    title: "Rice milling",
  },
  {
    code: "10612",
    title: "Wheat milling",
  },
  {
    code: "10613",
    title: "Provision of milling services",
  },
  {
    code: "10619",
    title: "Manufacture of grain mill products n.e.c.",
  },
  {
    code: "10620",
    title: "Manufacture of starches and starch products",
  },
  {
    code: "10711",
    title: "Manufacture of biscuits (including wafers and cones)",
  },
  {
    code: "10712",
    title:
      "Manufacture of bread, cakes and confectionery (excluding frozen bakery products)",
  },
  {
    code: "10719",
    title: "Manufacture of bakery products n.e.c.",
  },
  {
    code: "10720",
    title: "Manufacture of sugar and sugar products",
  },
  {
    code: "10731",
    title: "Manufacture of cocoa and chocolate powder from beans",
  },
  {
    code: "10732",
    title: "Manufacture of chocolate and chocolate products",
  },
  {
    code: "10733",
    title:
      "Manufacture of non-chocolate confectionery (e.g. sweets, toffees, crystallised fruits, chewing gums)",
  },
  {
    code: "10740",
    title:
      "Manufacture of macaroni, noodles, vermicelli and other related products",
  },
  {
    code: "10750",
    title: "Manufacture of cooked-food preparations (e.g. frozen dinners)",
  },
  {
    code: "10761",
    title: "Manufacture of instant beverages",
  },
  {
    code: "10762",
    title: "Processing of coffee seeds",
  },
  {
    code: "10763",
    title: "Manufacture of coffee powder",
  },
  {
    code: "10764",
    title: "Manufacture of tea (including processing of tea leaves)",
  },
  {
    code: "10765",
    title: "Manufacture of non-dairy creamer",
  },
  {
    code: "10791",
    title:
      "Manufacture of sauces including soya bean sauce (e.g. tomato sauce, chilli sauce)",
  },
  {
    code: "10792",
    title:
      "Manufacture of soya bean products except soya bean sauce and soya bean milk",
  },
  {
    code: "10793",
    title:
      "Manufacture and processing of spices (including curry powder and spice extracts)",
  },
  {
    code: "10794",
    title:
      "Manufacture of chips, crackers and other titbits (excluding biscuits and confectionery)",
  },
  {
    code: "10799",
    title:
      "Manufacture of other food products n.e.c. (except food chemicals and additives)",
  },
  {
    code: "10800",
    title:
      "Manufacture of prepared animal feeds (including additives for animal feed)",
  },
  {
    code: "11010",
    title:
      "Distilling, rectifying and blending of spirits; ethyl alcohol production from fermented materials (including samsu and Chinese liquors)",
  },
  {
    code: "11020",
    title: "Manufacture of wines",
  },
  {
    code: "11030",
    title: "Manufacture of malt liquors and malt (e.g. beer and stout)",
  },
  {
    code: "11041",
    title:
      "Manufacture of soft drinks, production of mineral waters (including carbonated waters, soya bean milk, barley water, chrysanthemum water)",
  },
  {
    code: "11042",
    title:
      "Manufacture of cordials, syrups and composite concentrates for beverages",
  },
  {
    code: "11043",
    title: "Manufacture of ice except dry ice",
  },
  {
    code: "12001",
    title: "Manufacture of cigarettes",
  },
  {
    code: "12009",
    title:
      "Manufacture of tobacco products n.e.c. (e.g. cigars, loose tobacco)",
  },
  {
    code: "13100",
    title: "Spinning, weaving and finishing of textiles",
  },
  {
    code: "13910",
    title: "Manufacture of knitted and crocheted fabrics and laces",
  },
  {
    code: "13921",
    title: "Manufacture of curtains, pillow cases, bed sheets and bed spreads",
  },
  {
    code: "13922",
    title:
      "Manufacture of other made-up house furnishings (e.g. napkins, table cloths)",
  },
  {
    code: "13929",
    title:
      "Manufacture of made-up textile articles except apparel n.e.c. (e.g. scrolls, flags and sails, banners, canvas products, cushions, pillows)",
  },
  {
    code: "13930",
    title: "Manufacture of carpets and rugs",
  },
  {
    code: "13940",
    title: "Manufacture of cordage, rope, twine and netting",
  },
  {
    code: "13990",
    title: "Manufacture of other textiles n.e.c. (e.g. upholstery filling)",
  },
  {
    code: "14101",
    title: "Manufacture of outer garments",
  },
  {
    code: "14102",
    title: "Manufacture of brassieres and other undergarments",
  },
  {
    code: "14103",
    title: "Tailoring and dressmaking (to individual order)",
  },
  {
    code: "14109",
    title:
      "Manufacture of wearing apparel except fur apparel n.e.c. (e.g. songkoks, caps, gloves and mittens, garters, handkerchiefs, raincoats, shawls and hair nets)",
  },
  {
    code: "14200",
    title:
      "Manufacture of fur apparel and articles of fur (e.g. fur rugs, mats and cushions)",
  },
  {
    code: "14301",
    title: "Manufacture of socks, stockings and other hosiery",
  },
  {
    code: "14302",
    title:
      "Manufacture of briefs, singlets and other knitted or crocheted wear (e.g. pullovers, cardigans)",
  },
  {
    code: "14309",
    title: "Manufacture of knitted and crocheted apparel n.e.c.",
  },
  {
    code: "15110",
    title: "Tanning and dressing of leather; dressing and dyeing of fur",
  },
  {
    code: "15121",
    title: "Manufacture of luggage, bags, brief cases and the like",
  },
  {
    code: "15122",
    title: "Manufacture of handbags",
  },
  {
    code: "15129",
    title:
      "Manufacture of saddlery, harness and articles of leather n.e.c. (e.g. coin purses, key cases)",
  },
  {
    code: "15200",
    title: "Manufacture of footwear",
  },
  {
    code: "16101",
    title: "Sawmilling",
  },
  {
    code: "16102",
    title: "Preservation of wood",
  },
  {
    code: "16109",
    title: "Sawmilling and planing of wood n.e.c.",
  },
  {
    code: "16211",
    title: "Manufacture of plywood and veneer",
  },
  {
    code: "16219",
    title:
      "Manufacture of laminboard, particle board and other panels and boards n.e.c.",
  },
  {
    code: "16291",
    title:
      "Manufacture of wooden window, door and other millwork, except prefabricated wooden building structures",
  },
  {
    code: "16292",
    title:
      "Manufacture of household and kitchen wooden ware except wooden footwear",
  },
  {
    code: "16293",
    title: "Manufacture of wooden containers",
  },
  {
    code: "16294",
    title: "Manufacture of attap and attap products",
  },
  {
    code: "16295",
    title: "Rattan processing",
  },
  {
    code: "16299",
    title:
      "Manufacture of other products of wood and articles of cork, straw and plaiting materials n.e.c.",
  },
  {
    code: "17010",
    title: "Manufacture of pulp, paper and paperboard",
  },
  {
    code: "17021",
    title: "Manufacture of corrugated paper and paperboard",
  },
  {
    code: "17022",
    title: "Manufacture of containers and boxes of paper and paperboard",
  },
  {
    code: "17091",
    title:
      "Manufacture of joss paper and other related ceremonial paper products (including effigies and other funeral paper goods and Chinese lanterns)",
  },
  {
    code: "17092",
    title:
      "Manufacture of sanitary towels, toilet paper, paper towels, disposable diapers and related paper products",
  },
  {
    code: "17093",
    title: "Manufacture of paper cups, plates and utensils",
  },
  {
    code: "17094",
    title: "Manufacture of cards, envelopes and stationery, unprinted",
  },
  {
    code: "17099",
    title:
      "Manufacture of other articles of paper and paperboard n.e.c. (e.g. papier mache, wallpaper)",
  },
  {
    code: "18111",
    title: "Printing of newspapers",
  },
  {
    code: "18112",
    title: "Printing of periodicals, books and magazines",
  },
  {
    code: "18113",
    title:
      "Commercial printing (e.g. printing of brochures, cards, envelopes, labels and stationery)",
  },
  {
    code: "18114",
    title: "Printing of continuous and fan-fold forms",
  },
  {
    code: "18115",
    title: "Silk screen printing (including metal printing)",
  },
  {
    code: "18119",
    title: "Printing n.e.c.",
  },
  {
    code: "18121",
    title: "Bookbinding (e.g. book packaging)",
  },
  {
    code: "18122",
    title: "Colour separation",
  },
  {
    code: "18129",
    title:
      "Service activities related to printing n.e.c. (including plate-making)",
  },
  {
    code: "18200",
    title:
      "Reproduction of recorded media (including reproduction of phonograph records, recorded magnetic tapes, compact discs and non-customised software)",
  },
  {
    code: "19100",
    title: "Manufacture of coke oven products",
  },
  {
    code: "19201",
    title: "Petroleum refineries",
  },
  {
    code: "19202",
    title: "Manufacture of petroleum lubricating oil",
  },
  {
    code: "19209",
    title: "Manufacture of refined petroleum products n.e.c.",
  },
  {
    code: "20111",
    title: "Manufacture of industrial gases and dry ice",
  },
  {
    code: "20112",
    title: "Manufacture of basic industrial acids and alkalis",
  },
  {
    code: "20113",
    title: "Manufacture of basic chemicals except fertilisers and nitrogen",
  },
  {
    code: "20114",
    title: "Manufacture of solvents",
  },
  {
    code: "20119",
    title: "Manufacture of basic chemicals n.e.c.",
  },
  {
    code: "20120",
    title: "Manufacture of fertilisers and nitrogen compounds",
  },
  {
    code: "20131",
    title:
      "Manufacture of petrochemical primary products (e.g. olefins, aromatics, basic building blocks)",
  },
  {
    code: "20132",
    title:
      "Manufacture of derivatives and intermediates produced from basic building blocks (e.g. acetyls, acrylics, oxochemicals, styrene, phenolic, ethylene oxide derivatives and polymers)",
  },
  {
    code: "20133",
    title: "Manufacture of synthetic rubber",
  },
  {
    code: "20210",
    title:
      "Manufacture of pesticides and other agro-chemical products (including insecticides, herbicides and disinfectants)",
  },
  {
    code: "20221",
    title: "Manufacture of paints",
  },
  {
    code: "20222",
    title: "Manufacture of varnishes and lacquers (including enamels)",
  },
  {
    code: "20229",
    title:
      "Manufacture of paints, varnishes and similar coatings and mastics n.e.c.",
  },
  {
    code: "20231",
    title:
      "Manufacture of soap, detergents, washing and other cleaning preparations",
  },
  {
    code: "20232",
    title: "Manufacture of perfumes and fragrances",
  },
  {
    code: "20233",
    title: "Manufacture of cosmetics and toiletries",
  },
  {
    code: "20234",
    title: "Manufacture of waxes and polishes and deodorising preparations",
  },
  {
    code: "20241",
    title:
      "Manufacture of food chemicals and additives (e.g. flavours, preservatives and colourings)",
  },
  {
    code: "20242",
    title:
      "Manufacture of additives for mineral oil (e.g. lubricant and fuel additives)",
  },
  {
    code: "20249",
    title: "Manufacture of additives n.e.c.",
  },
  {
    code: "20291",
    title:
      "Manufacture of epoxy moulding compounds and phenolic moulding compounds",
  },
  {
    code: "20292",
    title:
      "Manufacture of ultrapure electronic chemicals, materials and laminates",
  },
  {
    code: "20293",
    title:
      "Manufacture of water treatment, waste treatment and oilfield chemicals",
  },
  {
    code: "20294",
    title: "Manufacture of inks, dyestuffs, pigments and carbon black",
  },
  {
    code: "20295",
    title: "Manufacture of photographic film, paper, plate and chemicals",
  },
  {
    code: "20296",
    title: "Manufacture of adhesives and sealants",
  },
  {
    code: "20297",
    title: "Manufacture of catalysts and catalyst regeneration",
  },
  {
    code: "20298",
    title: "Manufacture of biofuels",
  },
  {
    code: "20299",
    title:
      "Manufacture of other chemical products n.e.c. (e.g. gelatin and derivatives, laundry blue, explosives, processed salts, industrial starch, incense and joss sticks, camphor products)",
  },
  {
    code: "20300",
    title: "Manufacture of man-made fibres except glass",
  },
  {
    code: "21011",
    title:
      "Manufacture of pharmaceutical intermediates and fine chemicals for human use",
  },
  {
    code: "21012",
    title:
      "Manufacture of pharmaceutical products and preparations for human use (e.g. tableted products)",
  },
  {
    code: "21013",
    title: "Manufacture of pharmaceutical products for veterinary use",
  },
  {
    code: "21021",
    title: "Manufacture of vaccines for human use",
  },
  {
    code: "21022",
    title:
      "Manufacture of other biological products and preparations for human use",
  },
  {
    code: "21023",
    title:
      "Manufacture of biological products and preparations for veterinary use",
  },
  {
    code: "21030",
    title: "Manufacture of Traditional Chinese Medicine",
  },
  {
    code: "22111",
    title: "Processing of rubber (including processing of rubber latex)",
  },
  {
    code: "22112",
    title: "Grading and packing of rubber",
  },
  {
    code: "22119",
    title: "Processing of natural gums n.e.c. (e.g. gum damar, jelutong)",
  },
  {
    code: "22121",
    title: "Manufacture of tyres and tubes",
  },
  {
    code: "22122",
    title: "Retreading and vulcanising of tyres",
  },
  {
    code: "22191",
    title:
      "Manufacture of foam rubber products (excluding foam rubber mattresses)",
  },
  {
    code: "22192",
    title: "Manufacture of rubber automotive parts",
  },
  {
    code: "22193",
    title: "Manufacture of industrial and mechanical rubber goods",
  },
  {
    code: "22194",
    title: "Manufacture of rubber floorings",
  },
  {
    code: "22199",
    title:
      "Manufacture of other rubber products n.e.c. (except rubber footwear and toys)",
  },
  {
    code: "22211",
    title:
      "Manufacture of plastic tableware, household and kitchen ware (excluding disposable utensils)",
  },
  {
    code: "22212",
    title: "Manufacture of styrofoam and plastic disposable utensils",
  },
  {
    code: "22213",
    title:
      "Manufacture of plastic sheet, film and articles thereof (e.g. polythene and polypropylene bags)",
  },
  {
    code: "22214",
    title:
      "Manufacture of plastic bottles, boxes and containers (except for household use)",
  },
  {
    code: "22215",
    title: "Manufacture of plastic pipes and tubes",
  },
  {
    code: "22216",
    title: "Manufacture of plastic precision engineering parts",
  },
  {
    code: "22217",
    title: "Manufacture of PVC files, holders and albums",
  },
  {
    code: "22218",
    title: "Manufacture of plastic cable ties",
  },
  {
    code: "22219",
    title:
      "Manufacture of plastic products n.e.c. (except plastic footwear and toys)",
  },
  {
    code: "22220",
    title: "Plastic product services",
  },
  {
    code: "23100",
    title:
      "Manufacture of glass and glass products (including yarn of glass fibres)",
  },
  {
    code: "23911",
    title: "Manufacture of bricks",
  },
  {
    code: "23912",
    title:
      "Manufacture of tiles, pipes and channels (including clay roofing, wall and floor tiles, and earthen and glazed pipes)",
  },
  {
    code: "23919",
    title: "Manufacture of clay products n.e.c.",
  },
  {
    code: "23931",
    title:
      "Manufacture of plumbing fixtures and fittings of vitreous china, china and earthenware (e.g. sinks, closets, bowls)",
  },
  {
    code: "23939",
    title: "Manufacture of other porcelain and ceramic products n.e.c.",
  },
  {
    code: "23940",
    title: "Manufacture of cement, lime and plaster",
  },
  {
    code: "23951",
    title: "Manufacture of ready-mix concrete",
  },
  {
    code: "23959",
    title:
      "Manufacture of articles of concrete, cement and plaster n.e.c. (except precast concrete components)",
  },
  {
    code: "23960",
    title:
      "Cutting, shaping and finishing of stone (including tomb-stones and furniture of stone or marble)",
  },
  {
    code: "23991",
    title: "Manufacture of asbestos products except asbestos paper",
  },
  {
    code: "23992",
    title: "Manufacture of fibreglass and fibreglass products",
  },
  {
    code: "23993",
    title:
      "Manufacture of other insulation products (e.g. ceramic IC packages, refractory materials, rock wool products, insulation services)",
  },
  {
    code: "23994",
    title:
      "Manufacture of non-structural pre-cast components (e.g. lightweight partitions, secondary roof slabs)",
  },
  {
    code: "23995",
    title: "Manufacture of asphalt premix",
  },
  {
    code: "23999",
    title:
      "Manufacture of other non-metallic mineral products n.e.c. (e.g. graphite products)",
  },
  {
    code: "24101",
    title: "Iron and steel rolling mills",
  },
  {
    code: "24102",
    title: "Manufacture of ferrous metal alloys",
  },
  {
    code: "24109",
    title: "Manufacture of basic iron and steel n.e.c. (including smelting)",
  },
  {
    code: "24201",
    title: "Tin smelting",
  },
  {
    code: "24202",
    title: "Manufacture of non-ferrous metal alloys",
  },
  {
    code: "24209",
    title: "Manufacture of basic precious and non-ferrous metals n.e.c.",
  },
  {
    code: "24310",
    title: "Iron and steel foundries",
  },
  {
    code: "24320",
    title: "Non-ferrous metal foundries",
  },
  {
    code: "25111",
    title:
      "Manufacture of metal doors, window and door frames, grilles and gratings",
  },
  {
    code: "25112",
    title: "Manufacture of metal strips and slabs (e.g. awnings)",
  },
  {
    code: "25113",
    title: "Manufacture of metal pipes and tubes",
  },
  {
    code: "25114",
    title: "Manufacture of steel structural component",
  },
  {
    code: "25119",
    title:
      "Manufacture of structural metal products n.e.c. (e.g. metal sections, corrugated sheets)",
  },
  {
    code: "25120",
    title:
      "Manufacture and repair of tanks, reservoirs and containers of metal (e.g. metal cylinders, storage vessels and containers for compressed or liquefied gas)",
  },
  {
    code: "25131",
    title:
      "Manufacture and repair of heating boilers, radiators and correctors (including parts)",
  },
  {
    code: "25139",
    title:
      "Manufacture and repair of steam generators n.e.c. (except central heating hot water boilers)",
  },
  {
    code: "25200",
    title:
      "Manufacture and repair of weapons and ammunition (e.g. ordnance and accessories)",
  },
  {
    code: "25911",
    title: "Iron and steel and non-ferrous forgings",
  },
  {
    code: "25912",
    title: "Manufacture of metal caps (e.g. crown corks)",
  },
  {
    code: "25913",
    title: "Manufacture of leadframe",
  },
  {
    code: "25914",
    title: "Metal stampings except metal caps",
  },
  {
    code: "25919",
    title: "Pressing and roll-forming of metal and powder metallurgy n.e.c.",
  },
  {
    code: "25921",
    title: "Electroplating and plating of metals and formed products",
  },
  {
    code: "25922",
    title: "Polishing of metals and formed products",
  },
  {
    code: "25923",
    title: "Rustproofing of metals and formed products",
  },
  {
    code: "25924",
    title: "Heat treatment for metals",
  },
  {
    code: "25929",
    title: "Metal product services n.e.c.",
  },
  {
    code: "25931",
    title: "Manufacture of table, kitchen and other cutlery",
  },
  {
    code: "25932",
    title: "Manufacture of hand and edge tools",
  },
  {
    code: "25939",
    title:
      "Manufacture of general hardware (including blacksmith shops) n.e.c. (e.g. locks and keys, hinges, clamps, hooks)",
  },
  {
    code: "25941",
    title:
      "Manufacture of uninsulated wire (e.g. electronic component wire, bonding wire)",
  },
  {
    code: "25949",
    title: "Manufacture of metal wire and cable products n.e.c.",
  },
  {
    code: "25951",
    title: "Manufacture of tinplate cans",
  },
  {
    code: "25952",
    title:
      "Manufacture of other tinplate and zinc articles (e.g. pails, buckets, dustbins)",
  },
  {
    code: "25959",
    title:
      "Manufacture of metal cans, containers and related products n.e.c. (e.g. barrels, steel drums, kegs)",
  },
  {
    code: "25991",
    title:
      "Manufacture of sanitary ware of enamelled iron and metal and plumbers' ware of brass",
  },
  {
    code: "25992",
    title:
      "Manufacture of plumbing, heating and related products (including parts) except electric",
  },
  {
    code: "25993",
    title:
      "Manufacture of bolts, nuts, rivets, fasteners and screw machine products",
  },
  {
    code: "25994",
    title: "Manufacture of pipe fittings",
  },
  {
    code: "25995",
    title: "Manufacture of springs",
  },
  {
    code: "25996",
    title:
      "Manufacture of kitchen and household utensils (e.g. steel shelf, showcase counters, kitchen trolleys display racks, sink bowls and other customised kitchen equipment)",
  },
  {
    code: "25997",
    title: "Manufacture of metal precision components",
  },
  {
    code: "25998",
    title: "Slitting, shearing and pasting of metal sheeting",
  },
  {
    code: "25999",
    title:
      "Manufacture of other fabricated metal products n.e.c. (including voice coil magnets)",
  },
  {
    code: "26111",
    title:
      "Manufacture of discrete devices (e.g. diodes, light-emitting diodes, transistors and rectifiers)",
  },
  {
    code: "26112",
    title: "Semiconductor wafer fabrication",
  },
  {
    code: "26113",
    title: "Assembly and testing of semiconductors",
  },
  {
    code: "26114",
    title: "Manufacture of solar wafers",
  },
  {
    code: "26115",
    title: "Manufacture of solar cells",
  },
  {
    code: "26119",
    title: "Manufacture of semiconductor devices n.e.c.",
  },
  {
    code: "26121",
    title: "Manufacture of capacitors",
  },
  {
    code: "26122",
    title: "Manufacture of printed circuit boards without electronic parts",
  },
  {
    code: "26123",
    title: "Manufacture of printed circuit boards with electronic parts",
  },
  {
    code: "26124",
    title:
      "Manufacture of piezo-electric devices (e.g. quartz crystals, quartz crystal filters, delay lines)",
  },
  {
    code: "26125",
    title:
      "Manufacture of electronic display devices except light emitting diodes",
  },
  {
    code: "26126",
    title: "Manufacture of electronic connectors",
  },
  {
    code: "26129",
    title: "Manufacture of other electronic components and boards n.e.c.",
  },
  {
    code: "26201",
    title:
      "Manufacture of computers and data processing equipment except computer peripheral equipment",
  },
  {
    code: "26202",
    title:
      "Manufacture of disk drives (including CD-ROM drives, DVD-ROM drives, optical drives, flash drives, tape drives, solid state drives, storage subsystems)",
  },
  {
    code: "26203",
    title: "Manufacture of printers",
  },
  {
    code: "26204",
    title:
      "Manufacture of smart cards and related products (e.g. smart card readers)",
  },
  {
    code: "26209",
    title: "Manufacture of computers and peripheral equipment n.e.c.",
  },
  {
    code: "26301",
    title:
      "Manufacture of telecommunications apparatus (e.g. PBX equipment, telephones except cellular)",
  },
  {
    code: "26302",
    title: "Manufacture of networking products (e.g. routers, switches)",
  },
  {
    code: "26303",
    title:
      "Manufacture of wireless communications equipment (e.g. cellular phones)",
  },
  {
    code: "26304",
    title:
      "Manufacture of electronic security systems (including electronic sensors, burglar and fire alarm systems)",
  },
  {
    code: "26309",
    title: "Manufacture of communications equipment n.e.c.",
  },
  {
    code: "26400",
    title: "Manufacture of consumer electronics",
  },
  {
    code: "26511",
    title: "Manufacture and repair of engineering and scientific instruments",
  },
  {
    code: "26512",
    title: "Manufacture and repair of measuring devices (e.g. meters)",
  },
  {
    code: "26513",
    title:
      "Manufacture and repair of process control equipment and related products",
  },
  {
    code: "26521",
    title: "Manufacture of fully electronic watches and clocks",
  },
  {
    code: "26522",
    title:
      "Manufacture of electro-mechanical watches and clocks (e.g. watches and clocks with moving parts and battery)",
  },
  {
    code: "26523",
    title: "Manufacture of mechanical watches and clocks",
  },
  {
    code: "26524",
    title: "Manufacture of parts and accessories for watches and clocks",
  },
  {
    code: "26601",
    title: "Manufacture and repair of electrotherapeutic devices",
  },
  {
    code: "26602",
    title:
      "Manufacture and repair of irradiation and electromedical equipment and instruments",
  },
  {
    code: "26701",
    title: "Manufacture of photographic equipment and parts (including lenses)",
  },
  {
    code: "26709",
    title:
      "Manufacture of optical instruments and photographic equipment n.e.c.",
  },
  {
    code: "26801",
    title: "Manufacture of disk media",
  },
  {
    code: "26802",
    title:
      "Manufacture of blank magnetic tapes, diskettes, Blu-ray technology discs, CDs, DVDs and VCDs",
  },
  {
    code: "2710",
    title:
      "Manufacture and Repair of Electric Motors, Generators, Transformers, Electricity Distribution and Control Apparatus",
  },
  {
    code: "27101",
    title: "Manufacture and repair of electric motors",
  },
  {
    code: "27102",
    title: "Manufacture and repair of electric generators",
  },
  {
    code: "27103",
    title: "Manufacture and repair of transformers",
  },
  {
    code: "27104",
    title:
      "Manufacture and repair of switchgear and switchboard apparatus (including switches, circuit breakers and electrical control panels)",
  },
  {
    code: "27109",
    title:
      "Manufacture and repair of electric motors, generators, transformers and electricity distribution and control apparatus n.e.c. (e.g. busbar trunking)",
  },
  {
    code: "27201",
    title: "Manufacture of primary batteries",
  },
  {
    code: "27202",
    title: "Manufacture of secondary batteries",
  },
  {
    code: "27321",
    title: "Manufacture of telecommunications cables and wires",
  },
  {
    code: "27322",
    title: "Manufacture of electric power cables and wires",
  },
  {
    code: "27323",
    title:
      "Manufacture of enamelled wires (used for winding of motors, transformers etc)",
  },
  {
    code: "27324",
    title: "Manufacture of wire and cable assemblies and harnesses",
  },
  {
    code: "27329",
    title:
      "Manufacture of other electronic and electric wires and cables n.e.c.",
  },
  {
    code: "27330",
    title: "Manufacture and repair of wiring devices",
  },
  {
    code: "27400",
    title:
      "Manufacture and repair of electric lamps and lighting equipment (including silver reflectors, fittings and parts)",
  },
  {
    code: "27501",
    title:
      "Manufacture of electrical household appliances (e.g. refrigerators, hot plates, toasters, food mixers, cookers, hair dryers, fans, shavers)",
  },
  {
    code: "27509",
    title:
      "Manufacture of domestic appliances n.e.c. (e.g. non-electric cooking ranges, grates, stoves)",
  },
  {
    code: "27901",
    title: "Manufacture of fuel cells",
  },
  {
    code: "27902",
    title:
      "Manufacture of electrical machinery, apparatus, appliances and supplies (including sound or visual signalling apparatus, electrical insulators except porcelain or glass)",
  },
  {
    code: "28111",
    title:
      "Manufacture and repair of engines and turbines except aircraft, vehicle, cycle and marine engines",
  },
  {
    code: "28112",
    title: "Manufacture and repair of marine engine",
  },
  {
    code: "28121",
    title:
      "Manufacture and repair of pumps (e.g. hydraulic pumps, drainage pumps)",
  },
  {
    code: "28122",
    title: "Manufacture and repair of taps, cocks and valves",
  },
  {
    code: "28129",
    title:
      "Manufacture and repair of pumps, compressors, taps and valves n.e.c.",
  },
  {
    code: "28141",
    title: "Manufacture of bearings (including disc and ball bearings)",
  },
  {
    code: "28142",
    title: "Manufacture and repair of gears, gearing and driving elements",
  },
  {
    code: "28150",
    title: "Manufacture and repair of ovens, furnaces and furnace burners",
  },
  {
    code: "28161",
    title:
      "Manufacture and repair of lifting and hoisting machinery except electrical",
  },
  {
    code: "28162",
    title: "Manufacture and repair of lifts and escalators",
  },
  {
    code: "28169",
    title:
      "Manufacture and repair of lifting and handling equipment n.e.c. (including conveying systems and industrial automated systems)",
  },
  {
    code: "28171",
    title:
      "Manufacture of office machinery and equipment (except computers and peripheral equipment)",
  },
  {
    code: "28172",
    title: "Repair and maintenance of office machinery and equipment",
  },
  {
    code: "28181",
    title:
      "Manufacture and repair of industrial robots (including Automated Guided Vehicles (AGVs))",
  },
  {
    code: "28182",
    title: "Manufacture and repair of service robots",
  },
  {
    code: "28189",
    title: "Manufacture and repair of robots n.e.c.",
  },
  {
    code: "28191",
    title:
      "Manufacture and repair of refrigerating, air-conditioning and ventilating machinery and equipment except household refrigerators",
  },
  {
    code: "28192",
    title:
      "Manufacture and repair of separation/mixing equipment (e.g. filters, separators, mixers)",
  },
  {
    code: "28193",
    title: "Manufacture and repair of solar modules and panels",
  },
  {
    code: "28194",
    title:
      "Manufacture and repair of welding and soldering equipment (including arc welding electrodes)",
  },
  {
    code: "28195",
    title: "Manufacture and repair of packaging machinery",
  },
  {
    code: "28199",
    title:
      "Manufacture and repair of other general purpose machinery and equipment except electrical and electronic n.e.c.",
  },
  {
    code: "28210",
    title: "Manufacture and repair of agricultural and forestry machinery",
  },
  {
    code: "28221",
    title:
      "Manufacture and repair of machinery and machine-tools - metal cutting types (e.g. lathes, milling machines, shaping machines)",
  },
  {
    code: "28222",
    title:
      "Manufacture and repair of machinery and machine-tools - metal forming types (e.g. forging press, cold heading machines, press breaks)",
  },
  {
    code: "28223",
    title: "Manufacture of dies, moulds, tools, jigs and fixtures",
  },
  {
    code: "28224",
    title:
      "Manufacture of machine tool accessories (e.g. small cutting tools, precision measuring tools and other attachments such as tool holders)",
  },
  {
    code: "28225",
    title:
      "Manufacture and repair of metal additive manufacturing (AM) equipment (including metal powder)",
  },
  {
    code: "28229",
    title:
      "Manufacture and repair of metal-forming machinery and machine tools n.e.c. (including metalworking machinery)",
  },
  {
    code: "28241",
    title: "Manufacture and repair of oil rigs",
  },
  {
    code: "28242",
    title:
      "Manufacture and repair of other oilfield and gasfield machinery and equipment (e.g. derricks, tool joints)",
  },
  {
    code: "28243",
    title: "Manufacture and repair of construction machinery and parts",
  },
  {
    code: "28249",
    title:
      "Manufacture and repair of machinery for mining, quarrying and construction n.e.c.",
  },
  {
    code: "28251",
    title: "Manufacture and repair of machinery for food products processing",
  },
  {
    code: "28252",
    title: "Manufacture and repair of machinery for beverage production",
  },
  {
    code: "28253",
    title: "Manufacture and repair of machinery for tobacco processing",
  },
  {
    code: "28260",
    title:
      "Manufacture and repair of machinery for textile, apparel and leather production",
  },
  {
    code: "28271",
    title: "Manufacture of probe cards",
  },
  {
    code: "28272",
    title: "Manufacture of bonding tools",
  },
  {
    code: "28273",
    title:
      "Manufacture and repair of semiconductor assembly and testing equipment (including computer burn-in system)",
  },
  {
    code: "28274",
    title: "Manufacture and repair of semiconductor foundry equipment",
  },
  {
    code: "28291",
    title: "Manufacture and repair of water/waste water treatment equipment",
  },
  {
    code: "28292",
    title: "Manufacture and repair of plastic processing machinery",
  },
  {
    code: "28293",
    title: "Manufacture and repair of woodworking machinery",
  },
  {
    code: "28294",
    title:
      "Manufacture and repair of polymer additive manufacturing (AM) equipment",
  },
  {
    code: "28299",
    title:
      "Manufacture and repair of other special purpose machinery n.e.c. (including plating equipment, industrial paint mixers and chemical mixing equipment)",
  },
  {
    code: "28300",
    title:
      "Installation of industrial machinery and equipment, mechanical engineering works",
  },
  {
    code: "29100",
    title: "Manufacture and assembly of motor vehicles",
  },
  {
    code: "29200",
    title:
      "Manufacture of motor vehicle bodies (coachwork), trailers and semi-trailers",
  },
  {
    code: "29300",
    title:
      "Manufacture of parts and accessories for motor vehicles (e.g. engine parts, brakes, clutches, axles, gear boxes)",
  },
  {
    code: "30110",
    title:
      "Building and repairing of ships, tankers and other ocean-going vessels (including conversion of ships into off-shore structures)",
  },
  {
    code: "30120",
    title: "Building and repair of pleasure crafts, lighters and boats",
  },
  {
    code: "30200",
    title:
      "Manufacture and repair of railway and tramway locomotives and rolling stock (including specialised parts except engines, electric motors and signalling equipment)",
  },
  {
    code: "30301",
    title:
      "Manufacture and assembly of aircraft and spacecraft (including aircraft engines and other parts)",
  },
  {
    code: "30302",
    title:
      "Repair and servicing of aircraft and spacecraft (including aircraft engines and other parts)",
  },
  {
    code: "30400",
    title: "Manufacture of military fighting vehicles",
  },
  {
    code: "30910",
    title:
      "Manufacture and assembly of motorcycles and scooters (including engines and other parts and accessories)",
  },
  {
    code: "30920",
    title:
      "Manufacture and assembly of bicycles, tricycles, trishaws and invalid carriages (including parts and accessories)",
  },
  {
    code: "30990",
    title:
      "Manufacture of other transport equipment n.e.c. (e.g. carts, wagons)",
  },
  {
    code: "31001",
    title:
      "Manufacture of furniture and fixtures of wood (including upholstery)",
  },
  {
    code: "31002",
    title: "Manufacture of furniture of rattan and cane",
  },
  {
    code: "31003",
    title: "Manufacture of furniture and fixtures primarily of metal",
  },
  {
    code: "31004",
    title:
      "Manufacture of furniture of other materials except primarily of stone, ceramic or concrete",
  },
  {
    code: "31005",
    title: "Manufacture of window and door screens and shades",
  },
  {
    code: "31006",
    title: "Manufacture of mattresses including those of foam rubber",
  },
  {
    code: "31009",
    title: "Manufacture of furniture n.e.c.",
  },
  {
    code: "32111",
    title: "Manufacture of jewellery except costume jewellery",
  },
  {
    code: "32112",
    title: "Manufacture of pewter ware",
  },
  {
    code: "32119",
    title:
      "Manufacture of jewellery and related articles n.e.c. (except costume jewellery)",
  },
  {
    code: "32120",
    title: "Manufacture of costume jewellery and related articles",
  },
  {
    code: "32200",
    title: "Manufacture of musical instruments",
  },
  {
    code: "32300",
    title:
      "Manufacture of sporting and athletic goods (including gym, diving and underwater equipment)",
  },
  {
    code: "32400",
    title: "Manufacture of games and toys",
  },
  {
    code: "32501",
    title:
      "Manufacture of medical research and clinical diagnostic instruments and supplies (e.g. reagents)",
  },
  {
    code: "32502",
    title:
      "Manufacture of medical disposables and surgical supplies (e.g. test kits, syringes, tubes, fluid administration sets)",
  },
  {
    code: "32503",
    title:
      "Manufacture of implantables and artificial limbs (e.g. heart valves, stents, orthopaedic implants)",
  },
  {
    code: "32504",
    title:
      "Manufacture of ophthalmic goods (e.g. spectacles, contact lenses and parts)",
  },
  {
    code: "32509",
    title:
      "Manufacture of medical and dental tools, instruments and supplies n.e.c.",
  },
  {
    code: "32909",
    title: "Other manufacturing industries n.e.c.",
  },
  {
    code: "35101",
    title: "Generation of electricity by fossil fuels",
  },
  {
    code: "35102",
    title:
      "Generation of electricity by other sources (e.g. solar power, biofuels etc)",
  },
  {
    code: "35103",
    title: "Transmission, distribution and sale of electricity",
  },
  {
    code: "35104",
    title: "Other related services (e.g. electricity brokers, meter reading)",
  },
  {
    code: "35201",
    title: "Manufacture of gas; distribution of gaseous fuels through mains",
  },
  {
    code: "35202",
    title: "Processing of natural gas",
  },
  {
    code: "35203",
    title: "Other related services (e.g. arranging for sale of natural gas)",
  },
  {
    code: "35300",
    title: "Supply of air or water for cooling or heating purposes",
  },
  {
    code: "36000",
    title:
      "Collection, purification and distribution of water (including desalination of water)",
  },
  {
    code: "37000",
    title: "Operation of sewer systems (including sewer treatment facilities)",
  },
  {
    code: "38100",
    title: "Collection of waste",
  },
  {
    code: "38200",
    title: "Treatment and disposal of waste (including remediation activities)",
  },
  {
    code: "38301",
    title: "Recycling of metal waste and scrap",
  },
  {
    code: "38309",
    title:
      "Recycling of non-metal waste n.e.c. (e.g. marine clay and oil sludge)",
  },
  {
    code: "41001",
    title:
      "General contractors (building construction including major upgrading works)",
  },
  {
    code: "41002",
    title: "Structural repair contractors",
  },
  {
    code: "41009",
    title: "Building construction n.e.c.",
  },
  {
    code: "42101",
    title: "General contractors (non-building construction)",
  },
  {
    code: "42102",
    title: "Road and railway construction",
  },
  {
    code: "42103",
    title: "Bridge, tunnel, viaduct and expressway construction",
  },
  {
    code: "42201",
    title: "Water and gas pipe-line and sewer construction",
  },
  {
    code: "42202",
    title: "Communications and power line construction",
  },
  {
    code: "42901",
    title: "Land reclamation works",
  },
  {
    code: "42902",
    title: "Dam and drainage construction",
  },
  {
    code: "42903",
    title: "Marine construction (e.g. harbours, piers, docks, wharves)",
  },
  {
    code: "42909",
    title:
      "Construction of other civil engineering projects n.e.c. (e.g. playground systems)",
  },
  {
    code: "43110",
    title: "Wrecking and demolition works",
  },
  {
    code: "43121",
    title:
      "Soil investigation, treatment and stabilisation (including grouting and guniting)",
  },
  {
    code: "43122",
    title: "Excavation and earthmoving works",
  },
  {
    code: "43129",
    title: "Site preparation n.e.c.",
  },
  {
    code: "43210",
    title: "Electrical works",
  },
  {
    code: "43220",
    title:
      "Installation of plumbing, heating (non-electric) and air-conditioning systems",
  },
  {
    code: "43291",
    title:
      "Installation and erection of building equipment (e.g. lifts, escalators, travellators)",
  },
  {
    code: "43292",
    title: "Installation of fire protection and security alarm systems",
  },
  {
    code: "43293",
    title: "Installation of building automated systems for remote monitoring",
  },
  {
    code: "43294",
    title: "Installation of awning and window shades",
  },
  {
    code: "43295",
    title:
      "Installation of thermal and sound insulation (including solar control films)",
  },
  {
    code: "43296",
    title: "Signcraft installation",
  },
  {
    code: "43299",
    title: "Other construction installation n.e.c.",
  },
  {
    code: "43301",
    title: "Renovation contractors",
  },
  {
    code: "43302",
    title: "Tile setting and plastering",
  },
  {
    code: "43303",
    title: "Joinery and other woodworks (e.g. laminated or parquet flooring)",
  },
  {
    code: "43304",
    title: "Painting and decorating",
  },
  {
    code: "43305",
    title:
      "Glass and glazing works (including mirror and shower screen installation)",
  },
  {
    code: "43306",
    title: "Curtain walling/cladding works",
  },
  {
    code: "43307",
    title: "Installation of doors, gates, grilles and windows",
  },
  {
    code: "43309",
    title: "Building completion and finishing n.e.c.",
  },
  {
    code: "43901",
    title:
      "Foundation works (including micropiling, conventional piling and underpinning)",
  },
  {
    code: "43902",
    title: "Brick laying, stone setting and cement works",
  },
  {
    code: "43903",
    title: "Roofing works (including timber carcassing)",
  },
  {
    code: "43904",
    title: "Production of pre-cast components",
  },
  {
    code: "43905",
    title: "Scaffolding works",
  },
  {
    code: "43906",
    title: "Sandblasting/Shotblasting works (except ships)",
  },
  {
    code: "43907",
    title:
      "Production of Prefabricated Prefinished Volumetric Construction (PPVC) modules",
  },
  {
    code: "43909",
    title:
      "Other specialised construction and related activities n.e.c. (excluding online marketplaces)",
  },
  {
    code: "46100",
    title:
      "Wholesale on a fee or commission basis (excluding online marketplaces)",
  },
  {
    code: "46211",
    title: "Wholesale of cut flowers and plants",
  },
  {
    code: "46212",
    title: "Wholesale of aquarium fishes (including food and accessories)",
  },
  {
    code: "46213",
    title:
      "Wholesale of pet birds and animals (including food and accessories)",
  },
  {
    code: "46219",
    title:
      "Wholesale of agricultural raw materials and live animals n.e.c. (excluding tropical produce)",
  },
  {
    code: "46221",
    title: "Wholesale of rubber (including rubber brokers)",
  },
  {
    code: "46222",
    title: "Wholesale of pepper and other spices",
  },
  {
    code: "46223",
    title: "Wholesale of coffee, cocoa and tea",
  },
  {
    code: "46224",
    title: "Wholesale of coconut",
  },
  {
    code: "46225",
    title: "Wholesale of palm oil",
  },
  {
    code: "46229",
    title: "Wholesale of tropical produce n.e.c.",
  },
  {
    code: "46301",
    title: "Wholesale of fruits and vegetables (including fresh and frozen)",
  },
  {
    code: "46302",
    title:
      "Wholesale of livestock, meat, poultry, eggs and seafood (including fresh and frozen)",
  },
  {
    code: "46303",
    title:
      "Wholesale of a general line (wide range) of groceries (e.g. cereals, sugar, edible oils, sauces and dairy products)",
  },
  {
    code: "46304",
    title: "Wholesale of confectionery and bakery products",
  },
  {
    code: "46305",
    title: "Wholesale of ice-cream",
  },
  {
    code: "46306",
    title: "Wholesale of health supplements",
  },
  {
    code: "46307",
    title: "Wholesale of liquor, soft drinks and beverages",
  },
  {
    code: "46308",
    title: "Wholesale of tobacco products",
  },
  {
    code: "46309",
    title:
      "Wholesale of food, beverages and tobacco n.e.c. (including dried or canned)",
  },
  {
    code: "46411",
    title: "Wholesale of textiles and leathers",
  },
  {
    code: "46412",
    title: "Wholesale of adults' clothing",
  },
  {
    code: "46413",
    title:
      "Wholesale of children and infants’ clothing (including products and accessories for infants)",
  },
  {
    code: "46414",
    title: "Wholesale of footwear",
  },
  {
    code: "46415",
    title: "Wholesale of bags, luggage and travel accessories",
  },
  {
    code: "46416",
    title:
      "Wholesale of sewing and clothing accessories (e.g. button, thread, lace, zip, belt, tie)",
  },
  {
    code: "46421",
    title: "Wholesale of jewellery made from precious metals and stones",
  },
  {
    code: "46422",
    title: "Wholesale of costume jewellery",
  },
  {
    code: "46423",
    title: "Wholesale of watches and clocks",
  },
  {
    code: "46424",
    title: "Wholesale of cosmetics and toiletries",
  },
  {
    code: "46429",
    title: "Wholesale of personal effects n.e.c.",
  },
  {
    code: "46431",
    title: "Wholesale of furniture (including mattresses, cushions)",
  },
  {
    code: "46432",
    title: "Wholesale of furnishings (including curtains, carpets, wallpaper)",
  },
  {
    code: "46433",
    title: "Wholesale of lighting and lighting accessories",
  },
  {
    code: "46434",
    title: "Wholesale of crockery, cutlery and kitchen utensils",
  },
  {
    code: "46435",
    title:
      "Wholesale of household electrical appliances and equipment (including household air-conditioners)",
  },
  {
    code: "46436",
    title:
      "Wholesale of audio and video equipment except electrical and electronic components (e.g. radio and television sets, sound reproducing and recording equipment)",
  },
  {
    code: "46437",
    title: "Wholesale of musical instruments and scores",
  },
  {
    code: "46439",
    title:
      "Wholesale of furniture, home furnishings and other household equipment n.e.c.",
  },
  {
    code: "46441",
    title:
      "Wholesale of sporting goods and equipment (including bicycles and healthcare equipment)",
  },
  {
    code: "46442",
    title: "Wholesale of music and video recordings (e.g. DVDs, Blu-ray discs)",
  },
  {
    code: "46443",
    title: "Wholesale of toys and games",
  },
  {
    code: "46444",
    title:
      "Wholesale of computer games (including electronic games and video game consoles)",
  },
  {
    code: "46449",
    title: "Wholesale of sporting and other recreational goods n.e.c.",
  },
  {
    code: "46451",
    title: "Wholesale of antiques and works of art",
  },
  {
    code: "46452",
    title: "Wholesale of handicrafts and gifts",
  },
  {
    code: "46453",
    title: "Wholesale of artificial flowers and plants",
  },
  {
    code: "46459",
    title: "Wholesale of handicrafts and fancy goods n.e.c.",
  },
  {
    code: "46461",
    title: "Wholesale of medicinal and pharmaceutical products (Western)",
  },
  {
    code: "46462",
    title: "Wholesale of medicine and herbs (other than Western)",
  },
  {
    code: "46471",
    title: "Wholesale of paper and paper products",
  },
  {
    code: "46472",
    title: "Wholesale of packaging materials",
  },
  {
    code: "46473",
    title: "Wholesale of stationery",
  },
  {
    code: "46474",
    title: "Wholesale of books and magazines",
  },
  {
    code: "46479",
    title:
      "Wholesale of paper, paper and cellophane products and stationery n.e.c.",
  },
  {
    code: "46491",
    title: "Wholesale of optical equipment and supplies (excluding binoculars)",
  },
  {
    code: "46492",
    title:
      "Wholesale of photographic equipment and supplies (including binoculars)",
  },
  {
    code: "46499",
    title: "Wholesale of other household goods n.e.c.",
  },
  {
    code: "46511",
    title:
      "Wholesale of computer hardware and peripheral equipment (except cybersecurity related hardware and peripheral equipment)",
  },
  {
    code: "46512",
    title:
      "Wholesale of computer software (except games and cybersecurity software)",
  },
  {
    code: "46513",
    title:
      "Wholesale of computer accessories (e.g. memory cards, computer cables)",
  },
  {
    code: "46514",
    title:
      "Wholesale of cybersecurity software, hardware and peripheral equipment",
  },
  {
    code: "46521",
    title: "Wholesale of telecommunications equipment (excluding handphones)",
  },
  {
    code: "46522",
    title: "Wholesale of electronic components",
  },
  {
    code: "46523",
    title:
      "Wholesale of handphones, handphone peripheral equipment and other telecommunications equipment",
  },
  {
    code: "46530",
    title: "Wholesale of agricultural machinery, equipment and supplies",
  },
  {
    code: "46541",
    title:
      "Wholesale of industrial machinery and equipment (e.g. industrial crane)",
  },
  {
    code: "46542",
    title: "Wholesale of construction equipment (e.g. concrete mixer)",
  },
  {
    code: "46543",
    title:
      "Wholesale of lifts, escalators and industrial and office air-conditioning equipment",
  },
  {
    code: "46544",
    title: "Wholesale of electrical and wiring accessories",
  },
  {
    code: "46549",
    title:
      "Wholesale of industrial, construction and related machinery and equipment n.e.c.",
  },
  {
    code: "46551",
    title:
      "Wholesale of marine equipment and accessories (including marine navigational equipment and radar)",
  },
  {
    code: "46552",
    title:
      "Wholesale of aircraft equipment and supplies (including aeronautical equipment)",
  },
  {
    code: "46559",
    title:
      "Wholesale of transport equipment except motor vehicles and motorcycles n.e.c.",
  },
  {
    code: "46561",
    title: "Wholesale of motor vehicles except motorcycles and scooters",
  },
  {
    code: "46562",
    title: "Wholesale of motorcycles and scooters",
  },
  {
    code: "46563",
    title: "Wholesale of parts and accessories for vehicles",
  },
  {
    code: "46591",
    title: "Wholesale of office machines and equipment (including accessories)",
  },
  {
    code: "46592",
    title:
      "Wholesale of medical, professional, scientific and precision equipment",
  },
  {
    code: "46593",
    title:
      "Wholesale of commercial food service equipment (e.g. hotel kitchen equipment)",
  },
  {
    code: "46594",
    title:
      "Wholesale of service establishment equipment and supplies (e.g. beauty salon equipment)",
  },
  {
    code: "46595",
    title: "Wholesale of security and safety equipment",
  },
  {
    code: "46599",
    title: "Wholesale of other machinery and equipment n.e.c.",
  },
  {
    code: "46610",
    title: "Wholesale of fuels and related products",
  },
  {
    code: "46620",
    title:
      "Wholesale of metals and metal ores (e.g. steel pipes) except general hardware",
  },
  {
    code: "46631",
    title: "Wholesale of logs, sawn timber, plywood and related products",
  },
  {
    code: "46632",
    title: "Wholesale of general hardware (e.g. locks, hinges)",
  },
  {
    code: "46633",
    title:
      "Wholesale of structural clay and concrete products (e.g. mosaic tiles, bricks)",
  },
  {
    code: "46634",
    title: "Wholesale of paints (including varnishes and supplies)",
  },
  {
    code: "46635",
    title: "Wholesale of glass",
  },
  {
    code: "46639",
    title:
      "Wholesale of construction materials, hardware, plumbing and heating equipment and supplies n.e.c. (e.g. cement, sand)",
  },
  {
    code: "46641",
    title: "Wholesale of basic industrial chemicals (except fertilisers)",
  },
  {
    code: "46642",
    title: "Wholesale of chemical fertilisers",
  },
  {
    code: "46643",
    title: "Wholesale of petrochemical products",
  },
  {
    code: "46649",
    title: "Wholesale of chemicals and chemical products n.e.c.",
  },
  {
    code: "46651",
    title: "Wholesale of scrap, junk and waste dealers",
  },
  {
    code: "46659",
    title: "Wholesale of other intermediate products, waste and scrap n.e.c.",
  },
  {
    code: "46661",
    title: "Ship chandlers",
  },
  {
    code: "46662",
    title: "Ship bunkering",
  },
  {
    code: "46900",
    title: "Wholesale trade of a variety of goods without a dominant product",
  },
  {
    code: "47101",
    title: "Supermarkets and hypermarkets",
  },
  {
    code: "47102",
    title: "Mini-marts, convenience stores and provision shops",
  },
  {
    code: "47103",
    title: "Department stores",
  },
  {
    code: "47109",
    title: "Retail sale in other non-specialised stores n.e.c.",
  },
  {
    code: "47211",
    title: "Retail sale of fruits and vegetables",
  },
  {
    code: "47212",
    title: "Retail sale of meat, poultry, eggs and seafood",
  },
  {
    code: "47213",
    title:
      "Retail sale of confectionery and bakery products (not manufactured on site)",
  },
  {
    code: "47214",
    title: "Retail sale of health supplements",
  },
  {
    code: "47219",
    title: "Retail sale of food n.e.c.",
  },
  {
    code: "47220",
    title: "Retail sale of beverages",
  },
  {
    code: "47230",
    title: "Retail sale of tobacco products",
  },
  {
    code: "47311",
    title: "Retail sale of motor vehicles except motorcycles and scooters",
  },
  {
    code: "47312",
    title: "Retail sale of motorcycles and scooters",
  },
  {
    code: "47313",
    title: "Retail sale of tyres and batteries",
  },
  {
    code: "47314",
    title: "Retail sale of spare parts and accessories for motor vehicles",
  },
  {
    code: "47320",
    title:
      "Retail sale of automotive fuel (e.g. petrol kiosks, CNG refilling stations)",
  },
  {
    code: "47411",
    title: "Retail sale of handphones and peripheral equipment",
  },
  {
    code: "47412",
    title:
      "Retail sale of computer hardware (including handheld computers) and peripheral equipment, and computer software (except games and cybersecurity hardware and software)",
  },
  {
    code: "47413",
    title:
      "Retail sale of computer games (including electronic games and video game consoles)",
  },
  {
    code: "47414",
    title:
      "Retail sale of computer and office equipment consumables (e.g. printer cartridges)",
  },
  {
    code: "47415",
    title:
      "Retail sale of cybersecurity software, hardware and peripheral equipment",
  },
  {
    code: "47420",
    title:
      "Retail sale of audio and video equipment (e.g. radio and television sets, sound reproducing and recording equipment)",
  },
  {
    code: "47510",
    title: "Retail sale of textiles",
  },
  {
    code: "47521",
    title: "Retail sale of paints",
  },
  {
    code: "47522",
    title: "Retail sale of hardware (e.g. chains, changkols, axes)",
  },
  {
    code: "47529",
    title:
      "Retail sale of hardware, paint and glass n.e.c. (e.g. wood, glass, sanitary ware, Do-It-Yourself materials)",
  },
  {
    code: "47531",
    title: "Retail sale of furniture (including mattresses, cushions)",
  },
  {
    code: "47532",
    title: "Retail sale of furnishings (e.g. curtains, carpets, pillow cases)",
  },
  {
    code: "47533",
    title: "Retail sale of lighting and lighting accessories",
  },
  {
    code: "47534",
    title: "Retail sale of crockery, cutlery and kitchen utensils",
  },
  {
    code: "47535",
    title:
      "Retail sale of household electrical appliances and equipment (e.g. vacuum cleaners, washing machines, refrigerators)",
  },
  {
    code: "47536",
    title: "Retail sale of musical instruments and scores",
  },
  {
    code: "47537",
    title: "Retail sale of security and safety equipment",
  },
  {
    code: "47539",
    title:
      "Retail sale of electrical household appliances, furniture, lighting equipment and other household articles n.e.c.",
  },
  {
    code: "47610",
    title:
      "Retail sale of books, newspapers and stationery (including newsvendors)",
  },
  {
    code: "47620",
    title:
      "Retail sale of music and video recordings (e.g. DVDs, Blu-ray discs)",
  },
  {
    code: "47630",
    title:
      "Retail sale of sports apparel and equipment (including bicycles, boats and healthcare equipment)",
  },
  {
    code: "47640",
    title: "Retail sale of games and toys",
  },
  {
    code: "47711",
    title: "Retail sale of clothing for adults",
  },
  {
    code: "47712",
    title:
      "Retail sale of children and infants’ clothing (including products and accessories for infants)",
  },
  {
    code: "47713",
    title: "Retail sale of footwear",
  },
  {
    code: "47714",
    title: "Retail sale of bags, luggage and travel accessories",
  },
  {
    code: "47715",
    title:
      "Retail sale of sewing and clothing accessories (e.g. button, thread, lace, zip, belt, tie)",
  },
  {
    code: "47719",
    title: "Retail sale of clothing, footwear and leather articles n.e.c.",
  },
  {
    code: "47721",
    title:
      "Retail sale of cosmetics and toiletries (including skin care products)",
  },
  {
    code: "47722",
    title: "Pharmacies and drug stores (Western)",
  },
  {
    code: "47723",
    title: "Chinese medicine shops",
  },
  {
    code: "47729",
    title: "Retail sale of pharmaceutical and medical goods n.e.c.",
  },
  {
    code: "47731",
    title: "Retail sale of jewellery made from precious metals and stones",
  },
  {
    code: "47732",
    title: "Retail sale of costume jewellery",
  },
  {
    code: "47733",
    title: "Retail sale of watches and clocks",
  },
  {
    code: "47739",
    title: "Retail sale of personal effects n.e.c.",
  },
  {
    code: "47741",
    title: "Retail sale of antiques and works of art",
  },
  {
    code: "47742",
    title: "Retail sale of handicrafts, collectibles and gifts",
  },
  {
    code: "47743",
    title: "Retail sale of cut flowers (including wreaths)",
  },
  {
    code: "47744",
    title: "Retail sale of artificial flowers and plants",
  },
  {
    code: "47745",
    title: "Retail sale of aquarium fishes (including food and accessories)",
  },
  {
    code: "47746",
    title:
      "Retail sale of pet birds and animals (including food and accessories)",
  },
  {
    code: "47749",
    title:
      "Retail sale of handicrafts and fancy goods, flowers, plants and pet animals n.e.c.",
  },
  {
    code: "47751",
    title: "Retail sale of spectacles and other optical goods",
  },
  {
    code: "47752",
    title: "Retail sale of cameras and other photographic goods",
  },
  {
    code: "47759",
    title: "Retail sale of optical and photographic goods n.e.c.",
  },
  {
    code: "47761",
    title: "Retail sale of joss paper and other ceremonial products",
  },
  {
    code: "47762",
    title: "Retail sale of liquefied petroleum gas (LPG)",
  },
  {
    code: "47769",
    title: "Retail sale of specific commodities n.e.c.",
  },
  {
    code: "47770",
    title: "Retail sale of second-hand goods in stores",
  },
  {
    code: "47801",
    title: "Stalls (including pushcarts) selling uncooked food",
  },
  {
    code: "47802",
    title:
      "Stalls (including pushcarts) selling other commodities (other than uncooked food)",
  },
  {
    code: "49101",
    title: "Railroad services",
  },
  {
    code: "49102",
    title: "Mass rapid transit systems (including light rail)",
  },
  {
    code: "49211",
    title: "Public bus services (scheduled services)",
  },
  {
    code: "49212",
    title: "Chartered bus services (including school buses)",
  },
  {
    code: "49213",
    title: "Inter-country bus services",
  },
  {
    code: "49214",
    title: "Street-hail and ride-hail service providers",
  },
  {
    code: "49215",
    title:
      "Rental and leasing of cars with driver (excluding street-hail and ride-hail service providers)",
  },
  {
    code: "49220",
    title: "Aerial cableways (including cable car services)",
  },
  {
    code: "49231",
    title: "Freight transport by road",
  },
  {
    code: "49232",
    title: "Moving services",
  },
  {
    code: "49233",
    title:
      "Rental and leasing of freight land transport vehicle (e.g. lorries and trucks) with driver",
  },
  {
    code: "49300",
    title: "Transport via pipelines",
  },
  {
    code: "50011",
    title: "International cruise services",
  },
  {
    code: "50012",
    title:
      "Other sight-seeing cruise services (e.g. harbour and river cruises)",
  },
  {
    code: "50013",
    title: "Passenger ferry services",
  },
  {
    code: "50014",
    title: "Chartering of ships and boats with crew (passenger)",
  },
  {
    code: "50021",
    title:
      "Shipping companies, including chartering of ships and boats with crew (freight)",
  },
  {
    code: "50022",
    title:
      "Operators and charterers of barges, tugboats and bumboats (freight)",
  },
  {
    code: "50023",
    title: "Branches of foreign shipping lines",
  },
  {
    code: "51001",
    title: "Airlines (passenger)",
  },
  {
    code: "51002",
    title: "Airlines (freight)",
  },
  {
    code: "51003",
    title: "Branches of foreign airlines (passenger)",
  },
  {
    code: "51004",
    title: "Branches of foreign airlines (freight)",
  },
  {
    code: "51005",
    title: "Aircraft chartering with operator",
  },
  {
    code: "52101",
    title: "General warehousing",
  },
  {
    code: "52102",
    title: "Refrigerated warehousing and storage",
  },
  {
    code: "52103",
    title: "Storage for dangerous goods",
  },
  {
    code: "52109",
    title:
      "Specialised storage n.e.c. (e.g. wine warehousing, document storage and warehousing)",
  },
  {
    code: "52211",
    title: "Carpark management and operation services",
  },
  {
    code: "52212",
    title: "Motor vehicle towing services",
  },
  {
    code: "52219",
    title: "Supporting services to land transport n.e.c.",
  },
  {
    code: "52221",
    title: "Representative offices of foreign shipping lines",
  },
  {
    code: "52222",
    title: "Shipping agencies (freight)",
  },
  {
    code: "52223",
    title: "Port operators",
  },
  {
    code: "52224",
    title: "Lighterage services",
  },
  {
    code: "52225",
    title: "Salvaging of distressed vessels and cargos",
  },
  {
    code: "52226",
    title: "Ship management services",
  },
  {
    code: "52227",
    title: "Ship brokering services",
  },
  {
    code: "52229",
    title: "Supporting services to water transport n.e.c.",
  },
  {
    code: "52231",
    title: "Representative offices of foreign airlines",
  },
  {
    code: "52232",
    title: "Airline agencies (freight)",
  },
  {
    code: "52233",
    title: "Airport operation services",
  },
  {
    code: "52234",
    title: "Airport terminal services (e.g. ground service activities)",
  },
  {
    code: "52239",
    title: "Supporting services to air transport n.e.c.",
  },
  {
    code: "52241",
    title: "Container depot services",
  },
  {
    code: "52242",
    title: "Crane services for all industries except construction",
  },
  {
    code: "52243",
    title: "Stevedoring services",
  },
  {
    code: "52251",
    title: "Classification societies",
  },
  {
    code: "52252",
    title: "Marine surveying services other than classification societies",
  },
  {
    code: "52291",
    title: "Cargo surveying services",
  },
  {
    code: "52292",
    title: "Freight transport arrangement",
  },
  {
    code: "53100",
    title: "National post activities (e.g. postal services)",
  },
  {
    code: "53200",
    title: "Courier activities other than national post activities",
  },
  {
    code: "55101",
    title: "Hotels",
  },
  {
    code: "55102",
    title: "Chalets",
  },
  {
    code: "55103",
    title: "Short term accommodation activities other than hotels and chalets",
  },
  {
    code: "55900",
    title:
      "Hostels and dormitories for students, workers and other individuals",
  },
  {
    code: "56111",
    title: "Restaurants",
  },
  {
    code: "56112",
    title: "Cafes",
  },
  {
    code: "56121",
    title: "Fast food outlets",
  },
  {
    code: "56122",
    title:
      "Food courts, coffee shops and canteens (with mainly food and beverage income)",
  },
  {
    code: "56123",
    title: "Food kiosks mainly for takeaway and delivery",
  },
  {
    code: "56130",
    title: "Pubs",
  },
  {
    code: "56140",
    title:
      "Stalls selling cooked food and prepared drinks (including stalls at food courts and mobile food hawkers)",
  },
  {
    code: "56200",
    title: "Food caterers",
  },
  {
    code: "58110",
    title:
      "Publishing of books, brochures, musical books and other publications",
  },
  {
    code: "58120",
    title:
      "Publishing of directories and mailing lists (including financial databases)",
  },
  {
    code: "58131",
    title: "Publishing of news",
  },
  {
    code: "58132",
    title: "Publishing of journals, periodicals and magazines",
  },
  {
    code: "58190",
    title: "Other publishing activities (excluding online marketplaces)",
  },
  {
    code: "58201",
    title: "Publishing of games software/applications",
  },
  {
    code: "58202",
    title: "Publishing of software/applications (non-games)",
  },
  {
    code: "59111",
    title: "Movie production",
  },
  {
    code: "59112",
    title: "Production of advertisements, corporate videos and event videos",
  },
  {
    code: "59113",
    title:
      "Production of dramas, variety shows and documentaries (including production of television programme)",
  },
  {
    code: "59114",
    title: "Animation production",
  },
  {
    code: "59119",
    title:
      "Movie, video, television and other programme production activities n.e.c.",
  },
  {
    code: "59120",
    title:
      "Movie, video, television and other programme post-production activities (including remastering)",
  },
  {
    code: "59131",
    title: "Movie distribution",
  },
  {
    code: "59132",
    title:
      "Distribution of television programmes (e.g. dramas, variety shows and documentaries)",
  },
  {
    code: "59139",
    title:
      "Movie, video, television and other programme distribution activities n.e.c.",
  },
  {
    code: "59140",
    title: "Movie projection activities (including cinemas)",
  },
  {
    code: "59201",
    title: "Sound recording production",
  },
  {
    code: "59202",
    title: "Music publishing and distribution activities",
  },
  {
    code: "60100",
    title: "Radio programme production and broadcasting",
  },
  {
    code: "60200",
    title:
      "Television programming and broadcasting (including cable, satellite, terrestrial television, internet and mobile)",
  },
  {
    code: "61011",
    title:
      "Wired telecommunications network operation (including fixed-line, fibre optics, local/international PSTN and leased circuits)",
  },
  {
    code: "61012",
    title:
      "Mobile cellular and other wireless telecommunications network operation",
  },
  {
    code: "61013",
    title: "Satellite telecommunications network operation",
  },
  {
    code: "61091",
    title:
      "Telecommunications resellers/third party telecommunications providers (including value-added network operators)",
  },
  {
    code: "61092",
    title: "Internet access providers, include ISPs",
  },
  {
    code: "61099",
    title:
      "Other telecommunications activities n.e.c. (e.g. provision of VoIP (Voice over Internet Protocol services))",
  },
  {
    code: "62011",
    title:
      "Development of software and applications (except games and cybersecurity)",
  },
  {
    code: "62012",
    title: "Development of computer games",
  },
  {
    code: "62013",
    title: "Development of software for cybersecurity",
  },
  {
    code: "62021",
    title: "Information technology consultancy (except cybersecurity)",
  },
  {
    code: "62022",
    title: "Information technology cybersecurity consultancy",
  },
  {
    code: "62023",
    title: "Computer facilities management activities",
  },
  {
    code: "62090",
    title:
      "Other information technology and computer service activities (e.g. disaster recovery services)",
  },
  {
    code: "63111",
    title: "Data centres",
  },
  {
    code: "63112",
    title: "Hosting services by non-data centres",
  },
  {
    code: "63119",
    title: "Data analytics, processing and related activities n.e.c.",
  },
  {
    code: "63120",
    title: "Internet search engines",
  },
  {
    code: "63201",
    title: "Online marketplaces for goods (including food)",
  },
  {
    code: "63202",
    title: "Online marketplaces for health services",
  },
  {
    code: "63203",
    title: "Online marketplaces for education services",
  },
  {
    code: "63204",
    title: "Online marketplaces for travel services",
  },
  {
    code: "63205",
    title: "Online marketplaces for real estate services",
  },
  {
    code: "63209",
    title: "Online marketplaces for services n.e.c.",
  },
  {
    code: "63901",
    title: "News agency activities",
  },
  {
    code: "63909",
    title: "Other information service activities n.e.c.",
  },
  {
    code: "64110",
    title: "Central bank",
  },
  {
    code: "64120",
    title: "Full banks",
  },
  {
    code: "64130",
    title: "Wholesale banks",
  },
  {
    code: "64140",
    title: "Merchant banks",
  },
  {
    code: "64150",
    title: "Finance companies (with deposit taking functions)",
  },
  {
    code: "64190",
    title: "Other monetary intermediation",
  },
  {
    code: "64201",
    title:
      "Bank/Financial holding companies (including insurance holding company)",
  },
  {
    code: "64202",
    title: "Other holding companies",
  },
  {
    code: "64300",
    title:
      "Trusts, funds and similar financial entities (e.g. collective portfolio investment funds (excluding those with rental income))",
  },
  {
    code: "64921",
    title: "Thrift and loan societies and credit associations",
  },
  {
    code: "64922",
    title: "Credit card services",
  },
  {
    code: "64923",
    title: "Mutual benefits organisations",
  },
  {
    code: "64924",
    title: "Money-lending",
  },
  {
    code: "64925",
    title: "Pawnbrokerage",
  },
  {
    code: "64929",
    title: "Other credit agencies n.e.c. (e.g. motor finance)",
  },
  {
    code: "64991",
    title: "Factoring companies",
  },
  {
    code: "64992",
    title: "Representative offices of foreign banks",
  },
  {
    code: "64993",
    title: "Remittance services",
  },
  {
    code: "64994",
    title: "Treasury centres",
  },
  {
    code: "64999",
    title:
      "Other financial service activities, except insurance and pension funding activities n.e.c.",
  },
  {
    code: "65110",
    title: "Life insurance",
  },
  {
    code: "65121",
    title: "Marine insurance",
  },
  {
    code: "65122",
    title: "Captive insurance",
  },
  {
    code: "65123",
    title: "Import, export and credit insurance",
  },
  {
    code: "65124",
    title:
      "General insurance (except marine and import, export & credit insurance)",
  },
  {
    code: "65201",
    title: "Life reinsurance",
  },
  {
    code: "65202",
    title: "General reinsurance",
  },
  {
    code: "65301",
    title: "Provident funding",
  },
  {
    code: "65302",
    title: "Pension funding",
  },
  {
    code: "66110",
    title: "Securities and commodities exchange",
  },
  {
    code: "66121",
    title: "Stock, share and bond brokers and dealers",
  },
  {
    code: "66122",
    title: "Foreign exchange brokers and dealers",
  },
  {
    code: "66123",
    title: "Gold bullion brokers and dealers",
  },
  {
    code: "66124",
    title: "Commodity (excluding gold) and futures brokers and dealers",
  },
  {
    code: "66125",
    title: "Carbon credit brokers/traders",
  },
  {
    code: "66126",
    title: "Money-changing services",
  },
  {
    code: "66129",
    title:
      "Security dealings and commodity contracts brokerage activities n.e.c.",
  },
  {
    code: "66191",
    title:
      "Trustee, fiduciary and custody services firms (including nominee companies, trustees and REIT trustees)",
  },
  {
    code: "66192",
    title: "Corporate finance advisory services",
  },
  {
    code: "66193",
    title: "Clearing and settlement of financial instruments",
  },
  {
    code: "66194",
    title:
      "Activities auxiliary to fund management activities (e.g. independent fund administration firms)",
  },
  {
    code: "66195",
    title: "Transaction/Payment processing services",
  },
  {
    code: "66199",
    title:
      "Other activities auxiliary to financial service activities n.e.c. (e.g. mortgage advisory firms)",
  },
  {
    code: "66210",
    title: "Risk and damage evaluation (e.g. loss adjusting, claims adjusting)",
  },
  {
    code: "66221",
    title:
      "Insurance agencies and agents (including financial planning services)",
  },
  {
    code: "66222",
    title: "Insurance brokers",
  },
  {
    code: "66291",
    title:
      "Representative offices of foreign insurers and professional reinsurers",
  },
  {
    code: "66292",
    title: "Captive managers",
  },
  {
    code: "66299",
    title:
      "Other activities auxiliary to insurance and pension funding n.e.c. (e.g. activities of actuaries)",
  },
  {
    code: "66301",
    title: "Traditional/Long-only asset/Portfolio management",
  },
  {
    code: "66302",
    title: "Hedge fund management",
  },
  {
    code: "66303",
    title:
      "Property fund management (including REIT management and direct property fund management)",
  },
  {
    code: "66304",
    title: "Private equity firms",
  },
  {
    code: "66305",
    title: "Venture capital activities",
  },
  {
    code: "66306",
    title:
      "Single/Multiple family offices activities (e.g. managing investments and trusts for a single or multiple families)",
  },
  {
    code: "66307",
    title: "Private debt funds",
  },
  {
    code: "66309",
    title: "Fund management activities n.e.c.",
  },
  {
    code: "68101",
    title: "Real estate developers",
  },
  {
    code: "68102",
    title: "Operating of serviced apartments",
  },
  {
    code: "68103",
    title:
      "Letting of self-owned or leased real estate property except food courts, coffee shops and canteens (e.g. office/exhibition space, shopping mall, self-storage facilities)",
  },
  {
    code: "68104",
    title:
      "Letting and operating of self-owned or leased food courts, coffee shops and canteens (with mainly rental income)",
  },
  {
    code: "68105",
    title: "Collective portfolio investment funds with rental income",
  },
  {
    code: "68106",
    title:
      "Management of self-owned strata titled property (i.e. Management Corporation Strata title)",
  },
  {
    code: "68201",
    title: "Real estate agencies and valuation services",
  },
  {
    code: "68202",
    title: "Town councils",
  },
  {
    code: "68203",
    title:
      "Residential (other than town councils), commercial and industrial real estate management",
  },
  {
    code: "68209",
    title:
      "Real estate activities on a fee or commission basis n.e.c. (excluding online marketplaces)",
  },
  {
    code: "69101",
    title: "Legal activities (excluding online marketplaces)",
  },
  {
    code: "69102",
    title: "Arbitration and mediation services (excluding online marketplaces)",
  },
  {
    code: "69201",
    title: "Accounting and auditing services (excluding online marketplaces)",
  },
  {
    code: "69202",
    title: "Book-keeping services (excluding online marketplaces)",
  },
  {
    code: "70101",
    title:
      "Activities of head and regional head offices; Centralised administrative offices and subsidiary management offices",
  },
  {
    code: "70102",
    title: "Business representative offices",
  },
  {
    code: "70201",
    title: "Management consultancy services",
  },
  {
    code: "70202",
    title: "Management consultancy services for healthcare organisations",
  },
  {
    code: "70203",
    title: "Management consultancy services for hotels",
  },
  {
    code: "70204",
    title: "Human resource consultancy services",
  },
  {
    code: "70205",
    title: "Public relations, marketing and brand consultancy services",
  },
  {
    code: "70206",
    title: "Port, shipping and maritime-related consultancy services",
  },
  {
    code: "70209",
    title: "Management consultancy services n.e.c.",
  },
  {
    code: "71111",
    title: "Architectural services",
  },
  {
    code: "71112",
    title: "Landscape design and landscape architecture",
  },
  {
    code: "71113",
    title: "Quantity surveying services",
  },
  {
    code: "71114",
    title: "Land surveying services",
  },
  {
    code: "71119",
    title:
      "Other surveying services n.e.c. (e.g. geophysical, geologic, seismic, hydrographic surveying services)",
  },
  {
    code: "71121",
    title: "General building engineering design and consultancy services",
  },
  {
    code: "71122",
    title:
      "Process and industrial plant engineering design and consultancy services",
  },
  {
    code: "71123",
    title:
      "Engineering design and consultancy services in energy management and clean energy systems",
  },
  {
    code: "71124",
    title:
      "Environmental engineering design and consultancy services (except clean energy)",
  },
  {
    code: "71125",
    title: "Infrastructure engineering design and consultancy services",
  },
  {
    code: "71126",
    title:
      "Engineering design and consultancy services supporting mining, oil and gas extraction and offshore exploration activities",
  },
  {
    code: "71129",
    title: "Engineering design and consultancy activities n.e.c.",
  },
  {
    code: "71200",
    title:
      "Technical testing and analysis services (including certification of products and services)",
  },
  {
    code: "72101",
    title:
      "Research and experimental development on biotechnology (excluding medical science)",
  },
  {
    code: "72102",
    title:
      "Research and experimental development on electronics (excluding medical science)",
  },
  {
    code: "72103",
    title:
      "Research and experimental development on chemicals (excluding medical science)",
  },
  {
    code: "72104",
    title: "Research and experimental development on engineering",
  },
  {
    code: "72105",
    title:
      "Research and experimental development on environment and clean technologies (excluding biotechnology)",
  },
  {
    code: "72106",
    title: "Research and experimental development on medical science",
  },
  {
    code: "72109",
    title: "Research and experimental development on natural sciences n.e.c.",
  },
  {
    code: "72200",
    title:
      "Research and experimental development on social sciences and humanities",
  },
  {
    code: "73100",
    title: "Advertising activities",
  },
  {
    code: "73200",
    title: "Market research and public opinion polling",
  },
  {
    code: "74111",
    title: "Electronics-related industrial design services",
  },
  {
    code: "74112",
    title: "Transport-related industrial design services",
  },
  {
    code: "74113",
    title: "Furniture design services",
  },
  {
    code: "74119",
    title: "Industrial design activities n.e.c.",
  },
  {
    code: "74191",
    title: "Interior design services",
  },
  {
    code: "74192",
    title: "Art and graphic design services",
  },
  {
    code: "74193",
    title: "Fashion (including accessories) design services",
  },
  {
    code: "74194",
    title: "Exhibition stand design services",
  },
  {
    code: "74199",
    title: "Other specialised design activities n.e.c.",
  },
  {
    code: "74200",
    title: "Photographic activities",
  },
  {
    code: "74901",
    title: "Translation services",
  },
  {
    code: "74902",
    title: "Brokerage and consultancy services of intellectual property assets",
  },
  {
    code: "74903",
    title: "Agrotechnology consultancy services",
  },
  {
    code: "74904",
    title: "Carbon consultancy services",
  },
  {
    code: "74905",
    title: "Agents for artistes, athletes, models and other performers",
  },
  {
    code: "74909",
    title: "Other professional, scientific and technical activities n.e.c.",
  },
  {
    code: "75000",
    title: "Veterinary activities",
  },
  {
    code: "77101",
    title:
      "Renting and leasing of private cars without driver (excluding online marketplaces)",
  },
  {
    code: "77102",
    title: "Renting and leasing of bicycles and inline/roller skates",
  },
  {
    code: "77109",
    title:
      "Renting and leasing of land transport equipment (except cars) without driver n.e.c. (excluding online marketplaces)",
  },
  {
    code: "77210",
    title: "Renting and leasing of recreational and sports goods",
  },
  {
    code: "77220",
    title: "Renting of VCDs and DVDs",
  },
  {
    code: "77291",
    title:
      "Renting and leasing of furniture, utensils and electronic equipment for household use",
  },
  {
    code: "77292",
    title: "Renting of attire (e.g. costumes)",
  },
  {
    code: "77299",
    title:
      "Renting and leasing of other personal and household goods n.e.c. (excluding online marketplaces)",
  },
  {
    code: "77310",
    title:
      "Renting of water transport equipment without operator (e.g. commercial boats and ships without operator)",
  },
  {
    code: "77320",
    title:
      "Renting of air transport equipment without operator (e.g. airplanes without operator)",
  },
  {
    code: "77330",
    title:
      "Renting of construction and civil engineering machinery and equipment",
  },
  {
    code: "77341",
    title: "Renting of computers and peripheral equipment",
  },
  {
    code: "77342",
    title: "Renting of office machinery and equipment",
  },
  {
    code: "77391",
    title:
      "Renting and leasing of professional radio and television sets and sound reproducing and recording equipment",
  },
  {
    code: "77392",
    title: "Renting of industrial machinery and equipment",
  },
  {
    code: "77399",
    title:
      "Renting of other machinery, equipment and tangible goods n.e.c. (excluding online marketplaces)",
  },
  {
    code: "77400",
    title:
      "Leasing of non-financial intangible assets (e.g. patents, trade marks, brand names etc)",
  },
  {
    code: "78101",
    title: "IT manpower contracting services",
  },
  {
    code: "78102",
    title: "Manpower contracting services (excluding IT manpower)",
  },
  {
    code: "78103",
    title:
      "Domestic worker employment placement agencies (excluding online marketplaces)",
  },
  {
    code: "78104",
    title:
      "Employment agencies (excluding domestic worker employment placement agencies and online marketplaces)",
  },
  {
    code: "78300",
    title: "Management of human resource functions",
  },
  {
    code: "79101",
    title: "Travel agencies and tour operators (mainly inbound)",
  },
  {
    code: "79102",
    title: "Travel agencies and tour operators (mainly outbound)",
  },
  {
    code: "79200",
    title: "Timesharing service activities",
  },
  {
    code: "80100",
    title: "Private security activities",
  },
  {
    code: "80200",
    title: "Detective and investigation activities",
  },
  {
    code: "81211",
    title:
      "General cleaning services (including cleaning of public areas, offices and factories) except household cleaning and online marketplaces",
  },
  {
    code: "81212",
    title:
      "Domestic/Household cleaning services (excluding online marketplaces)",
  },
  {
    code: "81291",
    title: "Pest control services not in connection with agriculture",
  },
  {
    code: "81292",
    title: "Cleaning of swimming pools, spas and fountains",
  },
  {
    code: "81299",
    title: "Other cleaning services n.e.c. (excluding online marketplaces)",
  },
  {
    code: "81300",
    title:
      "Landscape planting, care and maintenance service activities (e.g. garden installation and maintenance, grass cutting, tree felling, pruning)",
  },
  {
    code: "82110",
    title:
      "Office administrative services on a fee or contract basis (e.g. billing and record keeping)",
  },
  {
    code: "82191",
    title: "Stenographic and secretarial services",
  },
  {
    code: "82192",
    title: "Duplicating and photo-copying services",
  },
  {
    code: "82199",
    title: "Other specialised office support activities n.e.c.",
  },
  {
    code: "82200",
    title: "Call centres",
  },
  {
    code: "82301",
    title: "Exhibition organisers",
  },
  {
    code: "82302",
    title: "Convention/Conference organisers",
  },
  {
    code: "82303",
    title: "Event/Concert organisers",
  },
  {
    code: "82304",
    title: "Exhibition stand contractors",
  },
  {
    code: "82911",
    title: "Debt collection services",
  },
  {
    code: "82912",
    title: "Credit rating agencies",
  },
  {
    code: "82920",
    title: "Packaging activities",
  },
  {
    code: "82991",
    title: "Activities of auction houses",
  },
  {
    code: "82999",
    title:
      "Other business support service activities (e.g. administration of loyalty programmes)",
  },
  {
    code: "84110",
    title:
      "Executive, legislative and other general public administration (e.g. Istana, parliament, tax authority)",
  },
  {
    code: "84120",
    title: "Public administration of economic and social programmes",
  },
  {
    code: "84210",
    title: "Foreign affairs",
  },
  {
    code: "84221",
    title: "Armed forces",
  },
  {
    code: "84229",
    title: "Defence activities n.e.c.",
  },
  {
    code: "84231",
    title: "Judiciary and related services",
  },
  {
    code: "84232",
    title: "Police",
  },
  {
    code: "84233",
    title: "Prisons",
  },
  {
    code: "84234",
    title: "Other places of detention, correction and rehabilitation",
  },
  {
    code: "84235",
    title: "Civil defence",
  },
  {
    code: "84239",
    title: "Public order and safety activities n.e.c.",
  },
  {
    code: "85101",
    title: "Nurseries and kindergartens",
  },
  {
    code: "85102",
    title: "Primary schools",
  },
  {
    code: "85211",
    title: "Secondary schools including combined primary and secondary schools",
  },
  {
    code: "85212",
    title:
      "Junior colleges and centralised pre-university institutes (post-secondary non-tertiary) including combined secondary schools and post-secondary non-tertiary schools",
  },
  {
    code: "85213",
    title:
      "Commercial schools offering general secondary and post-secondary non-tertiary education programmes",
  },
  {
    code: "85220",
    title:
      "Technical and vocational secondary and post-secondary non-tertiary education",
  },
  {
    code: "85230",
    title: "International and foreign system schools",
  },
  {
    code: "85240",
    title: "Schools for special needs",
  },
  {
    code: "85250",
    title: "Specialised education schools",
  },
  {
    code: "85301",
    title: "Polytechnics",
  },
  {
    code: "85302",
    title: "Universities",
  },
  {
    code: "85303",
    title: "Teachers' training institute",
  },
  {
    code: "85304",
    title: "Commercial schools offering higher education programmes",
  },
  {
    code: "85401",
    title:
      "Training courses for construction, real estate, security and investigation services, cleaning, landscaping, waste management and pest management",
  },
  {
    code: "85407",
    title: "Training courses for sports and games",
  },
  {
    code: "85408",
    title: "Training courses for music, dancing, art, speech and drama",
  },
  {
    code: "85402",
    title:
      "Training courses for healthcare, education, community and social services",
  },
  {
    code: "85409",
    title: "Training courses n.e.c.",
  },
  {
    code: "85406",
    title: "Training courses for wholesale trade, logistics and transport",
  },
  {
    code: "85403",
    title:
      "Training courses for food and beverage, retail and accommodation, tour operators, travel agencies & travel-related reservation services",
  },
  {
    code: "85404",
    title: "Training courses for manufacturing",
  },
  {
    code: "85405",
    title:
      "Training courses for information and communications, finance and professional services",
  },
  {
    code: "85501",
    title: "Student recruitment agencies",
  },
  {
    code: "85502",
    title: "Examination and accreditation agencies for education services",
  },
  {
    code: "86101",
    title: "Acute hospitals",
  },
  {
    code: "86102",
    title: "Community hospitals",
  },
  {
    code: "86109",
    title: "Other hospitals n.e.c. (e.g. psychiatric hospital)",
  },
  {
    code: "86201",
    title: "Clinics and other general medical services (Western)",
  },
  {
    code: "86202",
    title: "Clinics and other general medical services (Non-Western)",
  },
  {
    code: "86203",
    title: "Specialised medical services (including day surgical centres)",
  },
  {
    code: "86204",
    title: "Dental services",
  },
  {
    code: "86901",
    title: "Medical laboratories (e.g. blood analysis laboratories)",
  },
  {
    code: "86902",
    title: "Medical diagnostic imaging centres (e.g. X-ray services)",
  },
  {
    code: "86903",
    title: "Ambulance and medical evacuation services",
  },
  {
    code: "86904",
    title: "Home healthcare services",
  },
  {
    code: "86905",
    title: "Dialysis services",
  },
  {
    code: "86909",
    title: "Other health services n.e.c. (excluding online marketplaces)",
  },
  {
    code: "87010",
    title:
      "Nursing and personal care facilities (including nursing homes and hospices)",
  },
  {
    code: "87021",
    title: "Residential care services for the elderly (e.g. home for the aged)",
  },
  {
    code: "87022",
    title:
      "Residential care services for children (e.g. children’s homes, orphanages)",
  },
  {
    code: "87029",
    title: "Other residential care services n.e.c.",
  },
  {
    code: "88101",
    title:
      "Social services for the elderly (e.g. befriender services, meal services, drop-in centres)",
  },
  {
    code: "88102",
    title:
      "Social services for persons with disabilities (e.g. day activity centres for people with disabilities)",
  },
  {
    code: "88911",
    title:
      "Child care services for pre-school children (including infant care services)",
  },
  {
    code: "88912",
    title:
      "Child/Student care services for school-going children (e.g. before and after-school care centres, student care centres)",
  },
  {
    code: "88913",
    title:
      "Social services for children and youths (e.g. youth outreach services, adoption services)",
  },
  {
    code: "88919",
    title:
      "Social services without accommodation for children, youth and families n.e.c.",
  },
  {
    code: "88920",
    title: "Community development councils and grassroots organisations",
  },
  {
    code: "88991",
    title: "Job training and vocational rehabilitation services",
  },
  {
    code: "88992",
    title:
      "Charitable and other supporting activities aimed at humanitarian work",
  },
  {
    code: "88999",
    title: "Other social services without accommodation n.e.c.",
  },
  {
    code: "90001",
    title: "Production of live stage presentations",
  },
  {
    code: "90002",
    title: "Performing arts venue operation",
  },
  {
    code: "90003",
    title: "Orchestras, musical bands, choirs and dance groups",
  },
  {
    code: "90009",
    title:
      "Dramatic arts, music and other arts production-related activities n.e.c. (e.g. stage, lighting and sound services)",
  },
  {
    code: "91010",
    title: "Libraries and archives activities",
  },
  {
    code: "91021",
    title: "Museums",
  },
  {
    code: "91029",
    title:
      "Preservation of historical sites, buildings, artefacts and paintings, cultural villages and other related activities n.e.c.",
  },
  {
    code: "91030",
    title: "Botanical and zoological gardens and nature reserves activities",
  },
  {
    code: "92001",
    title: "Horse-racing and other betting activities",
  },
  {
    code: "92002",
    title: "Casinos",
  },
  {
    code: "93111",
    title: "Fitness centres and gymnasiums",
  },
  {
    code: "93119",
    title:
      "Operation of sports facilities n.e.c. (e.g. bowling alleys, tennis centres, billiard halls, football stadium)",
  },
  {
    code: "93120",
    title: "Activities of sports clubs/associations and related activities",
  },
  {
    code: "93201",
    title: "Amusement theme parks",
  },
  {
    code: "93202",
    title:
      "Game arcade, online game aggregator, LAN game operators and gaming centres",
  },
  {
    code: "93203",
    title: "Night clubs, discotheques, dance clubs and karaoke lounges",
  },
  {
    code: "93204",
    title: "Recreation clubs (including country clubs)",
  },
  {
    code: "93209",
    title:
      "Other amusement and recreation activities n.e.c. (including recreation parks/beaches and recreational fishing)",
  },
  {
    code: "94110",
    title: "Activities of business and employers' membership organisations",
  },
  {
    code: "94120",
    title:
      "Activities of professional membership organisations (e.g. medical associations, law societies, accountancy bodies)",
  },
  {
    code: "94200",
    title: "Activities of trade unions",
  },
  {
    code: "94911",
    title: "Churches",
  },
  {
    code: "94912",
    title: "Mosques",
  },
  {
    code: "94913",
    title: "Buddhist/Taoist temples",
  },
  {
    code: "94914",
    title: "Hindu/Sikh temples",
  },
  {
    code: "94919",
    title: "Activities of religious organisations n.e.c.",
  },
  {
    code: "94920",
    title: "Activities of political organisations",
  },
  {
    code: "94991",
    title: "Philanthropic trusts, foundations and related activities",
  },
  {
    code: "94992",
    title: "Ethnic associations (including clan associations, huay kuans)",
  },
  {
    code: "94993",
    title:
      "Associations for hobbies, recreational, cultural and extra-curricular activities (e.g. scouts/girl guides association, book clubs and photo, music, art and craft clubs)",
  },
  {
    code: "94994",
    title:
      "Student/Alumni bodies and related associations (e.g. parent-teacher associations, old boys' associations)",
  },
  {
    code: "94995",
    title: "Organisations for the protection of the environment and animals",
  },
  {
    code: "94999",
    title: "Activities of other membership organisations n.e.c.",
  },
  {
    code: "95110",
    title:
      "Repair and maintenance of computer hardware, data processing equipment and computer peripherals",
  },
  {
    code: "95120",
    title:
      "Repair of communications equipment (e.g. mobile phones and fax machines)",
  },
  {
    code: "95210",
    title:
      "Repair of audio and video equipment (e.g. television sets, speakers and DVD players)",
  },
  {
    code: "95220",
    title:
      "Repair of domestic electrical/electronic appliances except audio and video equipment (e.g. refrigerators, washing machines and room air-conditioners)",
  },
  {
    code: "95230",
    title: "Repair of footwear and leather goods",
  },
  {
    code: "95240",
    title:
      "Repair and restoration of cabinets, furniture, upholstery, window shades and other fixtures",
  },
  {
    code: "95291",
    title: "Repair of bicycles and tricycles",
  },
  {
    code: "95292",
    title: "Repair of watches and clocks",
  },
  {
    code: "95293",
    title: "Repair of jewellery",
  },
  {
    code: "95299",
    title:
      "Repair of other personal and household goods n.e.c. (excluding online marketplaces)",
  },
  {
    code: "95301",
    title:
      "Repair and maintenance of motor vehicles (including installation of parts & accessories)",
  },
  {
    code: "95302",
    title: "Car washing and related services",
  },
  {
    code: "95303",
    title: "Vehicle inspection and valuation services",
  },
  {
    code: "96011",
    title: "Laundry and dry cleaning services except self-operated laundries",
  },
  {
    code: "96012",
    title: "Self-operated laundries",
  },
  {
    code: "96021",
    title: "Hairdressing salons/shops (including barber shops)",
  },
  {
    code: "96022",
    title:
      "Beauty salons and spas (including slimming, skin care and hair care centres)",
  },
  {
    code: "96023",
    title: "Manicure and pedicure services",
  },
  {
    code: "96024",
    title: "Massage parlours and foot reflexology",
  },
  {
    code: "96029",
    title:
      "Beauty and other personal care services n.e.c. (excluding online marketplaces)",
  },
  {
    code: "96030",
    title:
      "Funeral and related activities (including embalming, cremating and cemetery services, upkeep of cemeteries)",
  },
  {
    code: "96040",
    title:
      "Wedding and related activities (including bridal studios and wedding planners)",
  },
  {
    code: "96091",
    title: "Friendship, match-making and dating services",
  },
  {
    code: "96092",
    title: "Social escort services",
  },
  {
    code: "96093",
    title: "Astrology, palmistry and other fortune telling services",
  },
  {
    code: "96094",
    title: "Training of pets and animals",
  },
  {
    code: "96095",
    title: "Care services for pets and animals",
  },
  {
    code: "96099",
    title:
      "Other personal service activities n.e.c. (excluding online marketplaces)",
  },
  {
    code: "97001",
    title: "Activities of households as employers of domestic workers",
  },
  {
    code: "97002",
    title:
      "Activities of households as employers of other domestic personnel (e.g. housekeepers)",
  },
  {
    code: "99010",
    title: "Foreign embassies and trade representative offices",
  },
  {
    code: "99020",
    title: "Foreign armed forces",
  },
  {
    code: "99090",
    title: "Other extra-territorial organisations and bodies",
  },
];

export const ACTIVITY = [
  {
    code: '64202',
    title: 'Other holding companies',
  },
  {
    code: '64300',
    title:
      'Trusts, funds and similar financial entities (e.g. collective portfolio investment funds (excluding those with rental income))',
  },
  {
    code: '66123',
    title: 'Gold bullion brokers and dealers',
  },

  {
    code: '66124',
    title: 'Commodity (excluding gold) and futures brokers and dealers',
  },
  {
    code: '66192',
    title: 'Corporate finance advisory services',
  },
  {
    code: '66306',
    title:
      'Single/Multiple family offices activities (e.g. managing investments and trusts for a single or multiple families)',
  },

];

export const TASK_STATUS = [
  { value: 1, label: "Backlog" },
  { value: 2, label: "In progress" },
  { value: 3, label: "Complete" },
];

export const TASK_STATUS_ENUM = {
  BACKLOG: 1,
  INPROGRESS: 2,
  COMPLETE: 3,
};

export const SCORE_LIST = {
  isEmailVerified: 15,
  isPhonVerified: 15,
  isAddressVerified: 35,
  isIdentityVerified: 35,
};

export const SUBCRIPTION_PLAN_NAME = {
  MONTHLY: "month",
  ANNUALLY: "year",
};

// export const SUBCRIPTION_PLAN = [{
//   name: 'MONTHLY',
// },
// {
//   name: 'ANNUALLY',
// }]

export const LOGIN_TYPE = {
  ADMIN: 1,
  NORMAL: 2,
};

export const SELECT_BOX_THEME = (theme) => ({
  ...theme,
  borderRadius: "6px",
  borderColor: "#979797",
  colors: {
    ...theme.colors,
    primary50: 'var(--third-color)',
    primary25: 'var(--third-color)',
    primary: 'var(--primary-color)',
  },
});

export const CUSTOM_SELECT_STYLES = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? 'var(--primary-color)' : 'var(--primary-color)', // Change arrow color based on focus state
    '&:hover': {
      color: 'var(--primary-color)', // Change arrow color on hover
    },
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: state.isFocused ? 'var(--primary-color)' : 'var(--primary-color)', // Change cross icon color based on focus state
    '&:hover': {
      color: 'var(--primary-color)', // Change cross icon color on hover
    },
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '44px',
  }),
};

export const STATIC_EXTERNAL_URL = {
  CONTACT: "https://api.whatsapp.com/send?phone=6588993720",
  CUSTOMER_SERVICES: "https://inproved.com/customer-services/"
};

export const I_POP_OVER = {
  BUSINESS_NAME: 0,
  WEBSITE: 1,
  PRIMARY_ACTIVITY: 2,
  SECONDARY_ACTIVITY: 3,
  REGISTERED_ADDRESS: 4,
  SHAREHOLDERS: 5,
  DIRECTORS: 6,
  LICENSES: 7,
  VERIFY_BUSINESS: 8,
  BUSINESS_DETAIL: 9,
  SUGGESTED_PROFILE: 10,
  BOOK_OF_BUSINESS_ACTION_INDIVIDUAL: 11,
  BOOK_OF_BUSINESS_ACTION_COMPANY: 12,
  BOOK_OF_BUSINESS_SUGGESTED_PROFILE: 13,
  BOOK_OF_BUSINESS_SUGGESTED_POTENTIAL_PURPOSE: 14,
  SHAREHOLDERS_DIRECTOR_STATUS: 15,
  REFERAL_FRIEND: 16,
  DEFAULT_SUGGESTED_PROFILE: 17,
  BANK_TRANSFER_SENDING_AMOUNT: 18,
  CRYPTO_TRANSFER_SENDING_AMOUNT: 19,
  PAYMENT_PAID_UP_CAPITAL_LIMIT: 20,
  COMPANY_SUGGESTED_PROFILE: 21,
  COMPANY_SUGGESTED_ADD_PROFILE: 22,
  COMPANY_WARM_PROFILE: 23,
  COMPANY_MEETING_PROFILE: 24,
  PROMOTERS: 25,
  SHAREHOLDERS_DIRECTOR_INVITEE_STATUS: 26,
  CUSTOM_API_MESSAGE: 27,
  STRIPE_SENDING_AMOUNT: 28,
  STRIPE_AMOUNT: 29,
  STRIPE_TRANSACTION_FEES: 30,
  STRIPE_STORAGE_FEES: 31,
};

export const MODAL_BUSINESS_IDENTIFY = {
  LOGO: "logo",
  COMPANY_NAME: "companyName",
  COMPANY_WEBSITE: "companyWebsite",
  PRIMARY_ACTIVITY: "primaryActivity",
  PRIMARY_ADDRESS: "primaryAddress",
  UEN: "uen",
  REGISTRATION_DATE: "registrationDate",
  ENTITY_TYPE: "entityType",
  ENTITY_STATUS: "entityStatus",
  COMPANY_TYPE: "companyType",
  COUNTRY_OF_INCORPORATION: "countryOfIncorporation",
  BUSINESS_EXPIRY: "businessExpiry",
  BUSINESS_CONSTITUTION: "businessConstitution",
};

export const PREDEFINED_ADDRESS = {
  LINE1: "151 Chin Swee Road Manhattan House 02-24",
  CITY: "Singapore",
  COUNTRY: "Singapore",
  POSTAL_CODE: "169876",
};

export const VERIFICATION_TYPE = {
  MOBILE: 0,
  ADDRESS: 1,
  IDENTITY: 2,
};

export const secretPass = "SecretPass";

export const notificationAction = {
  NOTI_MYINFO_VERIFICATION: { label: "Myinfo verification", value: 1 },
  NOTI_VERIFY_BUSINESS: { label: "Verify business", value: 2 },
  NOTI_COMPLETE_DUE_DILIGENCE: { label: "Complete due diligence", value: 3 },
  NOTI_LEARN_MORE: { label: "Learn more", value: 4 },
  NOTI_INVITE_POTENTIAL_SHAREHOLDERS: {
    label: "Invite potential shareholders",
    value: 5,
  },
  NOTI_INVITE_POTENTIAL_DIRECTORS: {
    label: "Invite potential directors",
    value: 6,
  },
  NOTI_INVITE_POTENTIAL_PROMOTERS: {
    label: "Invite potential promoters",
    value: 7,
  },
  NOTI_CHAT_NOW: { label: "Chat now", value: 8 },
  NOTI_UPGRADE_NOW: { label: "Upgrade now", value: 9 },
  NOTI_VIEW_FILES: { label: "View files", value: 10 },
  NOTI_SCHEDULE_SHAREHOLDERS_MEETINGS: {
    label: "Schedule shareholders meeting",
    value: 11,
  },
  NOTI_SCHEDULE_DIRECTORS_MEETINGS: {
    label: "Schedule directors meeting",
    value: 12,
  },
  NOTI_SCHEDULE_PROMOTERS_MEETINGS: {
    label: "Schedule promoters meeting",
    value: 13,
  },
  NOTI_APPOINT_SHAREHOLDER: { label: "Appoint shareholder", value: 14 },
  NOTI_APPOINT_OFFICER: { label: "Appoint officer", value: 15 },
};

export const companyType = [
  { label: "Public Company Limited by Shares", value: 1 },
  { label: "Public Company Limited by Guarantee", value: 2 },
  { label: "Unlimited Public Company", value: 3 },
  { label: "Private Company Limited by Shares", value: 4 },
  { label: "Exempt Private Company Limited by Shares", value: 5 },
  { label: "Unlimited Private Company", value: 6 },
  { label: "Unlimited Exempt Private Company", value: 7 },
  { label: "Branch of a company registered in a foreign country ", value: 8 },
];

export const MESSAGING_LIMIT = 30;

export const ESIGN_TEMPLATE_TYPE = {
  TEMPLATE: 1,
  MANUAL: 2,
}

export const GOLD_BID_PRICE_CONST_KEY = {
  GOLD1KG: "gold1kg",
  GOLD1G: "gold1g",
  GOLD1KGHEDGE: "gold1kghedge",
}

export const PROFILE_STATUS = {
  SUGGESTED_PROFILES: 1,
  WARM_PROFILES: 2,
  MEETING_REQUESTS: 3,
};

export const PROFILE_ACTION = {
  REJECTED: -1,
  CONNECT: 1,
  REQUESTED: 2,
  SCHEDULED: 3,
  CONFIRM: 4,
  RESCHEDULED: 5,
  RESPOND: 6,
  ACCEPT: 7
};

export const BOB_CTA = {
  VIEW_ALL: 1,
  NOTIFICATION: 2,
  SUBSCRIPTION: 3,
};

export const BOB_SERVICES = {
  PROFILES: 1,
  PRODUCT_AND_SERVICES: 2,
  COMPANIES: 3,
};

export const BOB_SERVICES_NAME = {
  1: "Profiles",
  2: "Product and services",
  3: "Companies",
};

export const BOB_SERVICES_TEMPLATE_NAME = {
  1: "BOB Profiles.csv",
  2: "BOB Product Services.csv",
  3: "BOB Companies.csv",
};

export const SUBSCRIPTION_MODULE_TYPE = {
  PLAN: 1,
  PRODUCT: 2,
  BOB: 3,
  PAIDUPCAPITAL: 4,
  FUNDS: 5
};

export const SUBSCRIPTION_MODULE_TYPE_NAME = {
  1: "Plan",
  2: "Product",
  3: "Book of business",
  4: "Paid-up capital",
  5: "Funds"
};

export const BANNER_TYPE = {
  PLAN: 1,
  EXTERNAL: 2
}

export const CURRENCY = {
  USD: 'usd',
  SGD: 'sgd',
  INR: 'inr',
  CNY: 'cny',
}

export const XTRA_CURRENCY = {
  USD: 'usd',
  SGD: 'sgd',
  INR: 'inr',
  PKR: 'pkr',
  LKR: 'lkr',
  BDT: 'bdt',

}

export const CURRENCY_NAME = [
  { value: 'usd', 'label': 'United States Dollar (USD)' },
  { value: 'sgd', 'label': 'Singapore Dollar (SGD)' },
  { value: 'inr', 'label': 'Indian rupee (INR)' },
  { value: 'cny', 'label': 'Chinese Yuan (CNY)' },
]

export const XTRA_CURRENCY_NAME = [
  { value: 'pkr', 'label': 'Pakistani Rupee (PKR)' },
  { value: 'lkr', 'label': 'Sri Lankan Rupee (LKR)' },
  { value: 'bdt', 'label': 'Bangladeshi Taka (BDT)' },
]

export const CURRENCY_CRYPTO_NAME = [
  { value: 'btc', label: 'BTC' },
  { value: 'eth', label: 'ETH' },
  { value: 'usdt', label: 'USDT' },
  { value: 'usdc', label: 'USDC' },
]

export const XTRA_CURRENT_CRYPTO_NAME = [
  { value: 'xrp', label: 'XRP' },
  { value: 'aave', label: 'AAVE' },
  { value: 'doge', label: 'DOGE' },
  { value: 'sol', label: 'SOL' },
  { value: 'ltc', label: 'LTC' }
]

export const SUPPORTED_PAYMENT_METHOD_LIST = [
  { value: 1, name: 'Bank transfer' },
  { value: 2, name: 'Pay with card' },
  { value: 3, name: 'Pay with crypto' },
]

export const SUPPORTED_PAYMENT_METHOD = {
  BANK_TRANSFER: 1,
  PAY_WITH_CARD: 2,
  PAY_WITH_CRYPTO: 3,
}

export const SUPPORTED_PAYMENT_METHOD_NAME = {
  1: "Bank transfer",
  2: "Pay with card",
  3: "Pay with crypto",
}

export const USER_DASHBOARD_BULLION = {
  PERSONAL: 1,
  CASHBALANCE: 2,
  BULLIONBALANCE: 3,
  METALALLOCATION: 4,
  PRODUCTALLOCATION: 5,
  PROFITLOSS: 6,
};

export const CONTACT_US_TRIGER = {
  HOLDING_COMPANY: 1,
  SHARE_CAPITAL: 2,
  SHAREHOLDER: 3,
  DIRECTOR: 4,
  PAYMENT: 5,
  PROMOTER: 6,
  INDIVIDUAL_REFERRAL: 7,
  PAID_UP_CAPITAL_PAYMENT: 8,
  REMOVE_SHARE_DIRETOR_PROMOTER: 9,
  BOB_POTENTIAL_PURPOSE: 10,
  BOB_SPEAK_TO_ADMIN: 11,
}

export const CURRENCY_LOCALE_STRING = {
  usd: 'en-US',
  sgd: 'en-US',
  inr: 'en-IN',
  pkr: 'en-IN',
  lkr: 'en-IN',
  bdt: 'en-IN',
}

export const POTENTIAL_PURPOSES = [
  {
    value: "Potential customer",
    name: "Potential customer",
  },
  {
    value: "Potential investor",
    name: "Potential investor",
  },
  {
    value: "Potential director",
    name: "Potential director",
  },
  {
    value: "Potential employee",
    name: "Potential employee",
  },
  {
    value: "Potential vendor or supplier",
    name: "Potential vendor or supplier",
  },
];


export const USER_GUIDE_MODAL = {
  DEFAULT: 1,
  SUGGESTED: 2,
  COMPANY_SUGGESTED: 3,
  INVITE_REFERRAL: 4,
  PAYMENT_BENEFITS: 5,
}

export const BANK_TRANSFER_DETAILS = {
  wireTransferTo: "DBS Bank, 12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982",
  swiftBICCode: "DBSSSGSGXXX",
  bankAccountName: "DBS Bank",
  bankAccountAddress: "12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982",
  bankAccountNumber: "0720181913",
  beneficiaryName: "InProved Pte Ltd",
  beneficiaryAddress: "151 Chin Swee Road Manhattan House 02-24 Singapore 1649876"
}

export const CRYPTO_NETWORK_DETAILS = [{
  id: "BTC",
  network: "BTC",
  domainName: "bc1qgnaxp2htq830eudehhnnqxwrgtutpgx77lyxet",
  trustWallet: "https://link.trustwallet.com/send?address=bc1qgnaxp2htq830eudehhnnqxwrgtutpgx77lyxet&asset=c0"
}, {
  id: "ETH",
  network: "ERC20 or ETH",
  domainName: "0x7F88c67E21D6761C60D249a703C97169d89DA800",
  trustWallet: "https://link.trustwallet.com/send?address=0x7F88c67E21D6761C60D249a703C97169d89DA800&asset=c60"
}, {
  id: "USDT",
  network: "ERC20 or ETH",
  domainName: "0x7F88c67E21D6761C60D249a703C97169d89DA800",
  trustWallet: "https://link.trustwallet.com/send?asset=c60_t0xdAC17F958D2ee523a2206206994597C13D831ec7&address=0x7F88c67E21D6761C60D249a703C97169d89DA800"
}, {
  id: "USDC",
  network: "ERC 20 or ETH",
  domainName: "0x7F88c67E21D6761C60D249a703C97169d89DA800",
  trustWallet: "https://link.trustwallet.com/send?address=0x7F88c67E21D6761C60D249a703C97169d89DA800&asset=c60_t0xC93a59888E7E6f2849bA94AcF767266299C4c415"
}]

export const GOLD_QUANTITY_LIST = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
]

export const DEVICE_TYPE = {
  WEB: 1,
  ANDRIOD: 2,
  IOS: 3,
};

export const ADMIN_USER = ['support@inproved.com']


export const PUSH_NOTIFICATION_MODULE = {
  ESIGN: "ESIGN",
  INVITE: "INVITE"
}

export const PRODUCT_GOLD_TYPE = {
  KILOGRAM: 1,
  GRAM: 2,
  HEDGEKILOGRAM: 3,
}

export const PRODUCT_GOLD_TYPE_NAME_INITIAL = {
  KILOGRAM: 'kg',
  GRAM: 'g',
  HEDGEKILOGRAM: 'hkg',
}