import React from "react";
import { useLocation } from "react-router";
import { RoutesPaths } from "../../Router/constant";
import { privacy, terms, termsOfAdvisoryPlan } from "../../constants";

export default function CopyrightFooter() {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const ignoreCopyRight = [RoutesPaths.SUCCESS_SIGNNOW, RoutesPaths.E_SIGN, RoutesPaths.MESSAGE_DETAIL, RoutesPaths.ADMIN_LOGIN_VERIFICATION, RoutesPaths.INVITE_ACCEPT]

  const validateShow = () =>{
    return !ignoreCopyRight.includes(location.pathname)
  }

  return (
    <>
    {validateShow() && (
      <div className="container-fluid">
        <div className="row footerSection">
          <div className="col-md-6">
            <p className="m-0 footerLeftText">
              Copyright © {currentYear} InProved Pte Ltd
            </p>
            <p className="m-0 footerLeftText">
              (UEN 201616594C, PSPM license PS20190001819)
            </p>
          </div>
          <div className="col-md-6 linkSection">
            <div>
              <a
                href={terms}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of service
              </a>
            </div>
            <div>
              <a
                href={termsOfAdvisoryPlan}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of advisory plan
              </a>
            </div>
            <div>
              <a
                href={privacy}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
}
