import React, { useState, useEffect } from 'react';
import ContentLoader from "react-content-loader";
import { MESSAGE_TYPE, SUBSCRIPTION_MODULE_TYPE, USER_DASHBOARD_BULLION } from '../../../../constants';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserDashboardAction } from '../../../../actions/userDashboard';
import { useNavigate } from 'react-router';
import { RoutesPaths } from '../../../../Router/constant';
import { setPaymentMethodSelection } from '../../../../actions/stripe';
import { getPlanProductAction } from '../../../../actions/subscriptionPlan';
import { companyLink } from '../../../../components/ContactUs/ContactUs';

export default function CashBalance({ isEditing, setIsEditing, sendMessageToAdmin, adminLoader }) {

  const {
    control,
    reset,
    register,
    handleSubmit,
  } = useForm();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { selectedCompany } = useSelector((state) => state.company);
  const { userDashboard, isUserDashboardLoading } = useSelector((state) => state.userDashboard);
  const { isAdminLoggedIn } = useSelector((state) => state.auth);
  const { productPlanList } = useSelector((state) => state.subscriptionPlanProducts);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "cashBalanceRecord",
  });

  useEffect(() => {
    if (selectedCompany && selectedCompany?.id) {
      dispatch(getPlanProductAction(selectedCompany?.id, 1, 10000));
      dispatch(setPaymentMethodSelection(null));
    }
  }, [dispatch, selectedCompany])


  useEffect(() => {
    const getCashBalance = () => {
      reset();
      userDashboard && userDashboard?.cashBalanceRecord?.length > 0 && userDashboard?.cashBalanceRecord.forEach(element => {
        append({
          currency: element?.currency,
          balance: element?.balance
        })
      });
    }
    getCashBalance();
  }, [userDashboard])

  const onSubmit = async (data) => {
    await dispatch(updateUserDashboardAction(data))
    setIsEditing(null);
  };

  const addFunds = () => {
    const slug = productPlanList?.filter(x => x?.planType?.value === SUBSCRIPTION_MODULE_TYPE.FUNDS)[0]?.slug
    if (slug) {
      navigate(RoutesPaths.DASHBOARD_PRODUCT_PAGE.replace(":slug", slug));
    } else {
      navigate(RoutesPaths.PRODUCTS_LIST_PAGE)
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='accountBorderSection'>

          {isUserDashboardLoading ? (
            <div className='row cashHederSectionPadding'>
              <div className='col-md-12'>
                <div style={{ width: '100%', marginLeft: 'auto' }}>
                  <ContentLoader width="100%" height={15}>
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                  </ContentLoader>
                </div>
              </div>
            </div>
          ) : (
            <div className='row cashHederSectionPadding'>
              <div className='col-md-5 col-6 button_align'>
                <h6 className='cashHeaderText cashDarkText m-0'>Cash balance</h6>
              </div>

              <div className='col-md-7 col-6 flexSection mobileAlign'>
                {/* <div>
                  {isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? (
                    <input
                      type='text'
                      className='form-control customInputField textInputAlignText textInputAlignTextM'
                      {...register("cashBalance", {})}
                      defaultValue={userDashboard?.cashBalance || ""}
                    />
                  ) : (
                    <h6 className='cashHeaderText cashGreyText m-0'>{userDashboard?.cashBalance || "-"}</h6>
                  )}
                </div> */}

                {/* {isAdminLoggedIn && (
                  isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? (
                    <button className="btn save-button customFilterButton iconMarginLeft" type='submit'>
                      Save
                    </button>
                  ) : (
                    <span className="btn btn-link  px-3 mb-0 edit-button iconMarginLeft" onClick={() => setIsEditing(USER_DASHBOARD_BULLION.CASHBALANCE)}>
                      <i className="fas fa-pencil-alt edit-button me-2"></i>
                    </span>
                  )
                )} */}
                {isAdminLoggedIn && (<span className="btn btn-link  px-3 mb-0 edit-button faded-edit"></span>)}

              </div>
            </div>
          )}



          <div className='col-12'><hr className='m-0'></hr></div>

          <div className='cashBottomPadding'>
            <div className='row'>
              <div className={`col-${isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? 5 : 6}`}><p className='currentBalanceText m-0'>Currency balance</p></div>

              <div className={`col-${isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? 5 : 6} rightAlignText`}><p className='currentBalanceText m-0'>Balance</p></div>

              {isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? (
                <div className='col-2 deleteIcon'><p className='currentBalanceText m-0'>Action</p></div>
              ) : (
                <></>
              )}
            </div>
            {isUserDashboardLoading ? (
              <div className='row'>
                <div className='col-12 currentBalanceDetails'>
                  <ContentLoader width="100%" height={15}>
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
                  </ContentLoader>
                </div>
              </div>
            ) : (
              fields?.map((record, index) => (
                <div key={record.id} className='row'>
                  <div className={`col-${isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? 5 : 6}`}>
                    {isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? (
                      <input
                        type='text'
                        className='form-control customInputField mb-2'
                        {...register(`cashBalanceRecord.${index}.currency`)}
                      />

                    ) : (
                      <h6 className='currentBalanceDetails m-0 text-uppercase'>{record?.currency || "-"}</h6>
                    )}
                  </div>
                  <div className={`col-${isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? 5 : 6} rightAlignText`}>
                    {isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? (
                      <input
                        type='text'
                        className='form-control customInputField mb-2 textInputAlignText'
                        {...register(`cashBalanceRecord.${index}.balance`)}
                      />
                    ) : (
                      <h6 className='currentBalanceDetails m-0'>{record?.balance || "-"}</h6>
                    )}
                  </div>
                  {isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? (
                    <div className='col-2 deleteIcon'>
                      <div onClick={() => { remove(index); }}>
                        <span className="mb-0">
                          <i className="fa fa-times-circle edit-button m-0"></i>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            )}

            {isEditing === USER_DASHBOARD_BULLION.CASHBALANCE ? (
              <div className='row'>
                <div className='col-12 addRowAlignIcon addRightPadding'>
                  <span className="btn btn-link  px-3 mb-0 edit-button" onClick={() => { append({ currency: "", balance: "" }); }}>
                    <i className="fa-solid fa-plus edit-button"></i>
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            {isUserDashboardLoading ? (
              <div className='row cashBottomButton'>
                <div className='col-6'>
                  <ContentLoader width="72%" height={50}>
                    <rect x="0" y="0" rx="10" ry="10" width="72%" height="40" />
                  </ContentLoader>
                </div>
                <div className='col-6 skeletonFlexEnd'>
                  <div style={{ width: '65%', marginLeft: 'auto' }}>
                    <ContentLoader width="100%" height={50}>
                      <rect x="0" y="0" rx="10" ry="10" width="100%" height="40" />
                    </ContentLoader>
                  </div>
                </div>
              </div>
            ) : (
              <div className='row cashBottomButton'>
                <div className='col-6'>
                  {/* sendMessageToAdmin(MESSAGE_TYPE.USER_DASHBOARD, "<strong>Add funds</strong>: I am interested to add funds to my account", 1)} */}
                  <button disabled={(adminLoader === 1 || adminLoader > 0) ? true : false} type="button" onClick={addFunds} className='customFilterButton mobileViewImportButton'>
                    {adminLoader === 1 && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Add funds
                  </button>
                </div>
                <div className='col-6 rightAlignText'>
                  <button disabled={(adminLoader === 2 || adminLoader > 0) ? true : false} type="button" onClick={() => sendMessageToAdmin(MESSAGE_TYPE.USER_DASHBOARD, `<strong>Withdraw funds</strong>: I am interested to withdraw funds for ${companyLink(selectedCompany)}`, 2)} className='customImportButton mobileViewImportButton m-0'>
                    {adminLoader === 2 && (<i className="animateSpin fa-solid fa-circle-notch"></i>)} Withdraw funds
                  </button>
                </div>
              </div >
            )}
          </div>
        </div>
      </form>
    </>
  )
}
