import React, { useEffect, useRef, useState } from "react";
import Productheader from "./Productheader";
import Productfooter from "./Productfooter";
import { useForm } from "react-hook-form";
import { RoutesPaths } from "../../Router/constant";
import { LOGIN_TYPE } from "../../constants";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { signup } from "../../actions/auth";
import CurrencyFormat from "../../components/currencyFormat/currencyFormat";
import { userBidGoldPriceAction } from "../../actions/user";
import { Link } from "react-router-dom";

export default function Productlanding() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordView, setPasswordView] = useState(false);
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);
  const { bidGoldPrice } = useSelector((state) => state.user);
  const registerBuySectionRef = useRef(null);
  useEffect(() => {
    dispatch(userBidGoldPriceAction())
  }, [])

  const handleScrollToSection = () => {
    // Scroll to the Register Buy Section
    if (registerBuySectionRef.current) {
      registerBuySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      data.type = LOGIN_TYPE.NORMAL;
      dispatch(signup(data))
        .then((resp) => {
          if (resp.data && typeof resp.data === 'string' && resp.data.includes("verification")) {
            navigate(RoutesPaths.PRODUCT_THANK_YOU);
          }
        })
        .catch(() => { });
    } else {
      toast.error("Password and confirm password does not match.");
    }
  };

  return (
    <>
      <Productheader handleScrollToSection={handleScrollToSection} />

      <div className="herosSection" ref={registerBuySectionRef}>
        <div className="container-fluid screenResolation">
          <div className="row">
            <div className="col-md-6 imageSection">
              <img
                src="assets/img/landingPage/heroSectionGold.svg"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-md-6">
              <div className="formShadeSection">
                <div>
                  <h3 className="heroHeadTitle">
                    Secure your future with 1 kg <span>gold</span> bullion
                  </h3>
                  <p className="heroSectionPera">
                    Acquire 1 kg gold bullion securely stored in vaults located
                    in Singapore. With every purchase, you receive a proof of
                    deposit and a vault receipt, ensuring the safekeeping and
                    fulfilment of your investment.
                  </p>
                  <hr className="hrline" />
                  <div className="priceSectionMain">
                    <div className="priceSectionUpDown">
                      <img src="assets/img/landingPage/arrowup.svg" alt="" />
                      <p className="m-0">
                        {bidGoldPrice?.gold1kg?.currency?.name} <CurrencyFormat currency={bidGoldPrice?.gold1kg?.currency?.value} value={parseFloat(bidGoldPrice?.gold1kg?.price)} decimal={2} /> <span>/ kg</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="container-fluid purchaseFormSection">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row purchaseFormSpace">
                      <div className="col-6 col-md-6 firstFieldPadding">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type="text"
                          className="form-control"
                          placeholder="First name"
                          {...register("first_name", {
                            required: true,
                            maxLength: 20,
                            minLength: 3,
                          })}
                        />
                        {errors.first_name?.type === "required" && (
                          <span className="errorMessage">
                            First name is required
                          </span>
                        )}
                        {errors.first_name?.type === "maxLength" && (
                          <span className="errorMessage">
                            First name may not be longer than 20 characters
                          </span>
                        )}
                        {errors.first_name?.type === "minLength" && (
                          <span className="errorMessage">
                            Minimum 3 characters required
                          </span>
                        )}
                      </div>
                      <div className="col-6 col-md-6 lastFieldPadding">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type="text"
                          className="form-control"
                          placeholder="Last name"
                          {...register("last_name", { required: true })}
                        />
                        {errors.last_name?.type === "required" && (
                          <span className="errorMessage">
                            Last name is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row purchaseFormSpace">
                      <div className="col-md-12">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span className="errorMessage">
                            Email is required
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="errorMessage">Email is invalid</span>
                        )}
                      </div>
                    </div>

                    <div className="row purchaseFormSpace">
                      <div className="col-md-12 productViewRelative">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type={passwordView ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          {...register("password", {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        <div
                          className="productViewEye"
                          onClick={() => setPasswordView(!passwordView)}
                        >
                          <i
                            className={`fa fa-eye${passwordView ? "-slash" : ""
                              }`}
                            aria-hidden="true"
                          ></i>
                        </div>
                        {errors.password?.type === "required" && (
                          <span className="errorMessage">
                            Password is required
                          </span>
                        )}
                        {errors.password?.type === "minLength" && (
                          <span className="errorMessage">
                            Password must be atleast 8 characters long{" "}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row purchaseFormSpace">
                      <div className="col-md-12 productViewRelative">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type={confirmPasswordView ? "text" : "password"}
                          className="form-control"
                          placeholder="Confirm password"
                          {...register("confirmPassword", {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        <div
                          className="productViewEye"
                          onClick={() =>
                            setConfirmPasswordView(!confirmPasswordView)
                          }
                        >
                          <i
                            className={`fa fa-eye${confirmPasswordView ? "-slash" : ""
                              }`}
                            aria-hidden="true"
                          ></i>
                        </div>
                        {errors.confirmPassword?.type === "required" && (
                          <span className="errorMessage">
                            Confirm password is required
                          </span>
                        )}
                        {errors.confirmPassword?.type === "minLength" && (
                          <span className="errorMessage">
                            Confirm password must be atleast 8 characters long{" "}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row purchaseFormSpace">
                      <div className="col-md-12">
                        <div className="form-check form-switch">
                          <input
                            maxLength={50}
                            autoComplete="nope"
                            className="form-check-input"
                            type="checkbox"
                            id="rememberMe"
                            value=""
                            {...register("check", {
                              required: true,
                            })}
                          />
                          <label className="productLabel" htmlFor="rememberMe">
                            I agree with the{" "}
                            <a
                              href="https://inproved.com/terms-of-service/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Terms of Service
                            </a>
                            ,{" "}
                            <a
                              href="https://inproved.com/privacy-policy/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Privacy Policy
                            </a>
                            , and &nbsp;
                            <a
                              href="https://inproved.com/terms-of-advisory-plan/"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Terms of Advisory Plan
                            </a>
                          </label>
                          {errors.check?.type === "required" && (
                            <span className="errorMessage">
                              Please accept the terms of services, privacy
                              policy and terms of advisory plan before
                              proceeding
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn bg-gradient-info w-100 m-0"
                        >
                          Register an account to purchase
                        </button>
                      </div>
                    </div>
                    <label className="productLabel mt-3 mb-0">
                      Already have an account?{" "}
                      <Link
                        to={RoutesPaths.HOME}
                        className="font-weight-bolder">
                        Sign in
                      </Link>
                    </label>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 heroapplinkSection">
              <p>Get the app</p>
              <div>
                <a
                  href="https://apps.apple.com/us/app/inproved/id6478377459"
                  target="_blank"
                >
                  <img src="assets/img/landingPage/applestore.svg" alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.inproved"
                  target="_blank"
                >
                  <img src="assets/img/landingPage/playstore.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid screenResolation keyFeatures">
        <div className="row ">
          <div className="col-md-12">
            <h2 className="keyFrameTitle m-0">Key Features</h2>

            <div className="contatiner-fluid">
              <div className="row keyFrameInnerSection">
                <div className="col-md-3 col-6 keyFrameInner borderRight mobilemargin">
                  <img src="assets/img/landingPage/tickicon.svg" alt="" />
                  <p className="m-0">Purity</p>
                  <h6 className="m-0">99.99%</h6>
                </div>
                <div className="col-md-3 col-6 keyFrameInner borderRight mobilemargin">
                  <img src="assets/img/landingPage/tickicon.svg" alt="" />
                  <p className="m-0">Weight</p>
                  <h6 className="m-0">1 kg (32.151 troy oz)</h6>
                </div>

                <div className="col-md-3 col-6 keyFrameInner borderRight">
                  <img src="assets/img/landingPage/tickicon.svg" alt="" />
                  <p className="m-0">Purity</p>
                  <h6 className="m-0">99.99%</h6>
                </div>

                <div className="col-md-3 col-6 keyFrameInner">
                  <img src="assets/img/landingPage/tickicon.svg" alt="" />
                  <p className="m-0">Purity</p>
                  <h6 className="m-0">99.99%</h6>
                </div>
              </div>
            </div>
            <div className="keyDescriptionSection">
              <h6>
                Our gold bullion bars, sourced from reputable refiners, contain
                a minimum of .999 gold. InProved reserves the right to:
              </h6>
              <p>Dispatch gold bars directly to the storage facility.</p>
              <p>
                Substitute the 1 kg bar with smaller denominations (e.g.,
                7x100g, 4x50g, 5x20g) to total 1 kg.
              </p>
              <p>
                {" "}
                Fulfil your order at a later date while guaranteeing your funds
                are secured.{" "}
              </p>
              <p>
                Each bar is marked with a refinery stamp or mint mark,
                certifying its purity and weight.For other products,
              </p>
              <p> certificates may or may not be included.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="assuredSection">
        <div className="container-fluid screenResolation ">
          <div className="row">
            <div className="col-md-12">
              <h1 className="keyFrameTitle AssuredTitle m-0">
                What You're Assured Of
              </h1>
            </div>
          </div>
          <div className="row assuredRowSection">
            <div className="col-md-4 assuredColSection">
              <img
                src="assets/img/landingPage/assumedFrame.png"
                alt=""
                className="img-fluid"
              />
              <p>Bullion stored in a secured facility in Singapore.</p>
            </div>

            <div className="col-md-4 assuredColSection">
              <img
                src="assets/img/landingPage/assumedFrame2.png"
                alt=""
                className="img-fluid"
              />
              <p>
                Complete safety and security of your funds, ensuring they are
                solely used for bullion procurement.
              </p>
            </div>

            <div className="col-md-4 assuredColSection">
              <img
                src="assets/img/landingPage/assumedFrame3.png"
                alt=""
                className="img-fluid"
              />
              <p>
                Comprehensive procurement services provided by InProved's
                bullion experts.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="assuredSection guaranteedreceive">
        <div className="container-fluid screenResolation ">
          <div className="row">
            <div className="col-md-12">
              <h1 className="keyFrameTitle GuaranteedText m-0">
                What You're Guaranteed To Receive
              </h1>
            </div>
          </div>
          <div className="row assuredRowSection">
            <div className="col-md-4 assuredColSection guarantcolSection">
              <img
                src="assets/img/landingPage/guaranteedframe.png"
                alt=""
                className="img-fluid"
              />
              <p>Bullion stored in a secured facility in Singapore.</p>
            </div>

            <div className="col-md-4 assuredColSection guarantcolSection">
              <img
                src="assets/img/landingPage/guaranteedframe2.png"
                alt=""
                className="img-fluid"
              />
              <p>
                Complete safety and security of your funds, ensuring they are
                solely used for bullion procurement.
              </p>
            </div>

            <div className="col-md-4 assuredColSection guarantcolSection">
              <img
                src="assets/img/landingPage/guaranteedframe3.png"
                alt=""
                className="img-fluid"
              />
              <p>
                Comprehensive procurement services provided by InProved's
                bullion experts.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="investSection">
        <div className="container-fluid screenResolation">
          <div className="row">
            <div className="col-md-8">
              <h2 className="investTitle">
                Effortlessly invest in precious metals
              </h2>
              <div className="investmentGold">
                <img src="assets/img/landingPage/verified_user.svg" alt="" />
                <p className="m-0">
                  Unlock financial freedom with InProved gold investments
                </p>
              </div>
              <div className="investmentGold">
                <img src="assets/img/landingPage/verified_user.svg" alt="" />
                <p className="m-0">
                  Zero capital gains tax when you own bullion with InProved
                </p>
              </div>
              <div className="investmentGold">
                <img src="assets/img/landingPage/verified_user.svg" alt="" />
                <p className="m-0">Start your journey to prosperity today</p>
              </div>
            </div>
            <div className="col-md-4 registertoBuy" onClick={handleScrollToSection}>
              <button className="registerBuy">Register to Buy</button>
            </div>
          </div>
        </div>
      </div>

      <Productfooter />
    </>
  );
}
