import React from "react";
import { Link } from "react-router-dom";

export default function Productfooter() {
  return (
    <div className="footerbackground">
      <div className="container-fluid screenResolation footerSection">
        <div className="row">
          <div className="col-md-6 aligncenterimg">
            <img src="assets/img/landingPage/InProvedLogo.svg" alt="" />
          </div>
          <div className="col-md-6 socialIconSection">
            <div className="socialIcon cursorPointer">
              <a
                href="https://www.linkedin.com/company/inproved/?viewAsMember=true"
                target="_blank"
              >
                <img src="assets/img/landingPage/linkedin.svg" alt="" />
              </a>
            </div>
            <div className="socialIcon cursorPointer">
              <a href="https://x.com/In_Proved" target="_blank">
                <img src="assets/img/landingPage/twitter.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="row centerfooterSection">
          <div className="col-md-6 leftAppLink">
            <div className="alignCenter">
              <a
                href="https://apps.apple.com/us/app/inproved/id6478377459"
                target="_blank"
              >
                <img src="assets/img/landingPage/applestore.svg" alt="" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.inproved"
                target="_blank"
              >
                <img src="assets/img/landingPage/playstore.svg" alt="" />
              </a>
            </div>
          </div>
          <div className="col-md-6 InfoSection">
            <p className="m-0 userDetailsLinkHeader">Contact Us</p>
            <div className="">
              <a
                href="https://wa.me/+6588993720"
                target="_blank"
                rel="noreferrer"
                className="userDetailsLink"
              >
                <img src="assets/img/landingPage/whatsapp.svg" alt="" />
                <p className="m-0">Whatsapp: +65 8899 3720</p>
              </a>
            </div>
            <div className="">
              <a
                href="tel:+6569505010"
                target="_blank"
                rel="noreferrer"
                className="userDetailsLink"
              >
                <img src="assets/img/landingPage/call.svg" alt="" />
                <p className="m-0">Product enquiries: +65 6950 5010</p>
              </a>
            </div>
            <div className="">
              <a
                href="mailto:support@inproved.com"
                rel="noreferrer"
                target="_blank"
                className="userDetailsLink"
              >
                <img src="assets/img/landingPage/mail.svg" alt="" />
                <p className="m-0">support@inproved.com</p>
              </a>
            </div>
          </div>
        </div>
        <div className="row bottomSection">
          <div className="col-md-6">
            <p className="m-0 policyText">
              Copyright © 2024 InProved Pte Ltd (UEN 201616594C, PSPM license
              PS20190001819)
            </p>
          </div>
          <div className="col-md-6 policyLinkSection">
            <a
              href="https://inproved.com/terms-of-service/"
              target="_blank"
              className="textDecorationNone"
            >
              <div className="policyText cursorPointer">Terms of service</div>
            </a>
            <a
              href="https://inproved.com/terms-of-advisory-plan/"
              target="_blank"
              className="textDecorationNone"
            >
              <div className="policyText cursorPointer">
                Terms of Advisory Plan
              </div>
            </a>
            <a
              href="https://inproved.com/privacy-policy/"
              target="_blank"
              className="textDecorationNone"
            >
              <div className="policyText cursorPointer">Privacy Policy</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
