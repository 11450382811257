import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { clearFly, logout, signup, socialLogin } from "../../actions/auth";
import { GoogleLogin } from "react-google-login";
import {
  kimbo_google_client_id,
  privacy,
  terms,
  termsOfAdvisoryPlan,
} from "../../constants";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { LOGIN_TYPE } from "../../constants";
import { RoutesPaths } from "../../Router/constant";
import { getInviteByTokenAction } from "../../actions/promoters";
import { IS_VALID_TOKEN } from "../../actions/types";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const { inviteRequest, isValidToken } = useSelector(
    (state) => state.promoters
  );
  const [passwordView, setPasswordView] = useState(false);
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const loadRef = useRef(true);

  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      data.type = LOGIN_TYPE.NORMAL;
      dispatch(signup(data))
        .then(() => {
          navigate(RoutesPaths.HOME);
        })
        .catch(() => {});
    } else {
      toast.error("Password and confirm password does not match.");
    }
  };

  const googleResponse = async (response) => {
    dispatch(socialLogin(response.tokenId))
      .then(() => {
        navigate("/dashboard");
      })
      .catch(() => {});
  };
  const onFailure = async (response) => {};

  useEffect(() => {
    const token = queryParameters.get("token");
    if (loadRef.current) {
      const takeAction = async () => {
        if (token) {
          localStorage.setItem("refToken", token);
          localStorage.setItem("INVITE_PROMOTER_TOKEN", token);
          if (localStorage.getItem("INVITE_PROMOTER_TOKEN")) {
            dispatch(clearFly());
            dispatch(getInviteByTokenAction(token));
          }
        } else {
          dispatch(getInviteByTokenAction("clear"));
        }
      };
      takeAction().catch(console.error);
    }

    return () => {
      loadRef.current = false;
    };
  }, [queryParameters]);

  useEffect(() => {
    const token = queryParameters.get("token");
    if (inviteRequest && token) {
      setValue("first_name", inviteRequest?.first_name);
      setValue("last_name", inviteRequest?.last_name);
      setValue("email", inviteRequest?.email);
      setValue("refToken", token);
    }
  }, [inviteRequest]);

  return (
    <>
      <section className="min-vh-100 mb-8 signup-back">
        <div
          className="page-header align-items-start min-vh-50 pt-5 pb-11 border-radius-lg"
          style={{
            backgroundImage: "url(assets/img/kimbocorp-site-signup.png)",
          }}>
          {/* <span className="mask  opacity-6"></span> */}
          {/* <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 text-center mx-auto">
                <h1 className="text-white mb-2 mt-5">Sign Up !</h1>
                <p className="text-lead text-white"></p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="container">
          <div className="row mt-lg-n10 mt-md-n11 mt-n10">
            <div className="col-xl-5 col-lg-5 col-md-7 mx-auto">
              {isValidToken ? (
                <div className="card z-index-0 card-login">
                  <div className="card-header text-center pt-4">
                    <h5>Register to diversify to bullion</h5>
                  </div>
                  {/* <div className="row text-center mx-auto">
                  <div className="col-lg-12">
                    <div className="text-center">
                      <GoogleLogin
                        clientId={kimbo_google_client_id}
                        buttonText="Login"
                        render={renderProps => (
                          <button
                            type="button"
                            onClick={renderProps.onClick}
                            className="btn w-100 mt-4 mb-0 google-login"
                          >
                            <img src="assets/img/google-logo.svg" alt="" />
                            Sign Up With Google{" "}
                          </button>
                        )}
                        onSuccess={googleResponse}
                        onFailure={onFailure}
                      />
                    </div>
                  </div>
                  <div className="mt-2 position-relative text-center">
                    <p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                      or
                    </p>
                  </div>
                </div> */}
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <input
                              maxLength={50}
                              autoComplete="nope"
                              type="text"
                              className="form-control"
                              placeholder="First name"
                              {...register("first_name", {
                                required: true,
                                maxLength: 20,
                                minLength: 3,
                              })}
                            />
                            {errors.first_name?.type === "required" && (
                              <span className="errorMessage">
                                First name is required
                              </span>
                            )}
                            {errors.first_name?.type === "maxLength" && (
                              <span className="errorMessage">
                                First name may not be longer than 20 characters
                              </span>
                            )}
                            {errors.first_name?.type === "minLength" && (
                              <span className="errorMessage">
                                Minimum 3 characters required
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <input
                              maxLength={50}
                              autoComplete="nope"
                              type="text"
                              className="form-control"
                              placeholder="Last name"
                              {...register("last_name", { required: true })}
                            />
                            {errors.last_name?.type === "required" && (
                              <span className="errorMessage">
                                Last name is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            required: true,
                            pattern:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                          })}
                        />
                        {errors.email?.type === "required" && (
                          <span className="errorMessage">
                            Email is required
                          </span>
                        )}
                        {errors.email?.type === "pattern" && (
                          <span className="errorMessage">Email is invalid</span>
                        )}
                      </div>
                      <div className="mb-3 handleEye">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type={passwordView ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          {...register("password", {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        <div
                          className="viewEye"
                          onClick={() => setPasswordView(!passwordView)}>
                          <i
                            className={`fa fa-eye${
                              passwordView ? "-slash" : ""
                            }`}
                            aria-hidden="true"></i>
                        </div>
                        {errors.password?.type === "required" && (
                          <span className="errorMessage">
                            Password is required
                          </span>
                        )}
                        {errors.password?.type === "minLength" && (
                          <span className="errorMessage">
                            Password must be atleast 8 characters long{" "}
                          </span>
                        )}
                      </div>
                      <div className="mb-3 handleEye">
                        <input
                          maxLength={50}
                          autoComplete="nope"
                          type={confirmPasswordView ? "text" : "password"}
                          className="form-control"
                          placeholder="Confirm password"
                          {...register("confirmPassword", {
                            required: true,
                            minLength: 8,
                          })}
                        />
                        {/* -slash */}
                        <div
                          className="viewEye"
                          onClick={() =>
                            setConfirmPasswordView(!confirmPasswordView)
                          }>
                          <i
                            className={`fa fa-eye${
                              confirmPasswordView ? "-slash" : ""
                            }`}
                            aria-hidden="true"></i>
                        </div>
                        {errors.confirmPassword?.type === "required" && (
                          <span className="errorMessage">
                            Confirm password is required
                          </span>
                        )}
                        {errors.confirmPassword?.type === "minLength" && (
                          <span className="errorMessage">
                            Confirm password must be atleast 8 characters long{" "}
                          </span>
                        )}
                      </div>
                      <div className="form-check form-check-info text-left">
                        <input
                          id="flexCheckDefault"
                          maxLength={50}
                          autoComplete="nope"
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          {...register("check", {
                            required: true,
                          })}
                        />
                        <p className="agree" htmlFor="flexCheckDefault">
                          I agree the{" "}
                          <a
                            className="terms"
                            href={terms}
                            target="_blank"
                            rel="noreferrer noopener">
                            Terms of services
                          </a>
                          ,{" "}
                          <a
                            className="terms"
                            href={privacy}
                            target="_blank"
                            rel="noreferrer noopener">
                            Privacy policy
                          </a>
                          , and{" "}
                          <a
                            className="terms"
                            href={termsOfAdvisoryPlan}
                            target="_blank"
                            rel="noreferrer noopener">
                            Terms of advisory plan
                          </a>
                        </p>
                      </div>
                      {errors.check?.type === "required" && (
                        <span className="errorMessage">
                          Please accept the terms of services, privacy policy
                          and terms of advisory plan before proceeding
                        </span>
                      )}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn bg-gradient-info w-100 my-4 mb-2">
                          Sign up
                        </button>
                      </div>
                      <p className="text-sm mt-3 mb-0">
                        Already have an account?{" "}
                        <Link
                          to={RoutesPaths.HOME}
                          className="text-dark font-weight-bolder">
                          Sign in
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="card z-index-0 card-login">
                  <div className="card-header text-center pt-4">
                    <h5>Invitation code invalid</h5>
                  </div>
                  <div className="card-body">
                    <p>
                      It appears that your invite code isn't valid or expired.
                      Please contact your account administrator and request
                      another invite.
                    </p>
                    <p className="text-sm mt-3 mb-0">
                      Already have an account?{" "}
                      <Link
                        to={RoutesPaths.HOME}
                        onClick={() =>
                          dispatch({ type: IS_VALID_TOKEN, payload: true })
                        }
                        className="text-dark font-weight-bolder">
                        Sign in
                      </Link>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
